@import "./w3.css";
@import "./variables.scss";

.font-primary {
  font-family: 'Raleway', sans-serif;
}

.font-secondary {
  font-family: 'Open Sans', sans-serif !important;
}

.color-blue {
  color:$textColor1
}

.color-blue-light{
  color:$textColor2
}

.color-pink{
  color:$primaryColor2
}

.te-color-5{
  color:$primaryColor5 
}

.headline-text-large{
  @extend .font-primary;
  font-size: 22px;
  font-weight: lighter;
  @media only screen and (min-width: 601px) {
    font-size: 32px;
  }
}

.headline-text-medium{
  @extend .font-primary;
  font-size: 24px;
  font-weight: 800;
}

.headline-text-small{
  @extend .font-primary;
  font-size: 18px;
  font-weight: bolder;
}

.body-text-large{
  @extend .font-primary;
  font-size: 18px;
}


.body-text-small{
  @extend .font-primary;
  font-size: 16px;
}

.sub-text-large{
  @extend .font-primary;
  font-size: 14px;
}

.te-align-middle{
  display: flex;
  justify-content: center;
  align-items: center;
}

.te-no-active-arrow-img{
  position: absolute;
  top: -66px;
  right: -34px;
}

.te-no-active-height{
  min-height: 70vh;
}

.te-active-events-top-section{
  display: flex;
  justify-content: space-between;
}

.sub-text-small{
  @extend .font-primary;
  font-size: 12px;
}

@media only screen and (max-width: 600px) {
  .headline-text-large{
    font-size: 24px;
  }
  .body-text-large{
    font-size: 16px;
  }
  .body-text-small{
    font-size: 14px;
  }
  .headline-text-medium{
    font-size: 20px;
  }
  .sub-text-large{
    font-size: 12px;
  }
  .mobile-no-padding{
    padding: 0 !important;
  }
  .mobile-no-margin{
    margin:0 !important;
  }
}

.App {
  text-align: center;
}

.font-cursive {
  font-family: "Urbanist", sans-serif;
}


// .font-primary {
//   // font-family: Raleway, sans-serif;
//     font-family: "Urbanist", sans-serif;
// }

.font-sans {
  // font-family: Work Sans, sans-serif;
    font-family: "Urbanist", sans-serif;
}

/* .body {
  color: rgba(0, 0, 0, 0.8) !important;
} */

.cyanBlueGradient {
  background-image: linear-gradient(#01fdfd, #00b0ff);
}

.login-button {
  border-radius: 20px;
  border: none;
  color: #fff;
  padding: 5px 40px;
  font-weight: 600;
}

.t-button {
  border-radius: 20px;
  width: auto;
  color: #fff;
  font-size: large;
  border: none;
}

.underline {
  margin-top: 20px;
  text-decoration: dashed;
  width: 100%;
  height: 1px;
  background-image: linear-gradient(to right, transparent 50%, #585858 50%);
  background-size: 20px 100%;
}

.title {
  margin-left: 125px;
  margin-top: 55px;
  font-size: xx-large;
}

.margin-top-40 {
  margin-top: 40px;
}

.homepage-p {
  margin-left: 125px;
  font-size: xx-large;
  color: grey;
}

.circle {
  height: 50px;
  width: 50px;
  background-image: linear-gradient(#ffffff, #45dcfe);
  border-radius: 50%;
  margin-top: 5px;
  display: inline-block;
}

.homepara {
  margin-left: 125px;
  font-size: xx-large;
  color: grey;
  font-weight: 800;
}

.homepage-card {
  background-color: #f2f2f2 !important;
  text-align: center;
  font-size: 20px;
  border-radius: 20px !important;
  margin-left: 200px;
  position: relative;
  top: -100px;
  /* width: 30%; */
  /* margin-left: 67%; */
}

.homepage-textfield {
  width: 330px;
  border-radius: 20px !important;
  margin-bottom: 15px !important;
}

.t-login-input {
  width: 330px;
  border-radius: 20px !important;
  margin-bottom: 15px !important;
}

.t-login- :focus {
  outline: none;
  border: none;
  border-radius: 20px !important;
}

.select {
  background-color: white !important;
  border-radius: 20px !important;
}

.homepage-button {
  margin: 30px !important;
  width: 110px;
  border-radius: 20px !important;
  background-color: rgb(12, 221, 221) !important;
}

.t-teampage-creategame-card {
  background-color: #f2f2f2 !important;
  text-align: center;
  font-size: 20px;
  border-radius: 20px !important;
  position: relative;
}

.cursor-pointer {
  cursor: pointer !important;
}

.game-button {
  background-color: black !important;
  color: white !important;
  border-radius: 10px !important;
  width: 100%;
}

.create-game-button {
  border-radius: 20px !important;
  width: 150px;
  margin-right: 0px;
  margin-left: 175px !important;
  margin-bottom: 20px !important;
  background-color: rgb(12, 221, 221) !important;
}
.w3-select {
  background-color: white !important;
}

.textarea-700 {
  width: 90%;
  border-radius: 25px !important;
  background-color: white;
  margin-bottom: 30px;
  font-size: 30px;
}

.textarea-700:focus {
  border-radius: 25px !important;
  outline: none;
}

.t-homepage {
  background-color: #f2f2f2 !important;
  text-align: center;
  font-size: 20px;
  border-radius: 20px !important;
  position: relative;
  top: -180px;
  width: 33%;
  margin-left: 63%;
}

.t-home-title {
  font-weight: bolder;
  padding-top: 10%;
  margin-bottom: 15%;
  font-size: 30px;
}

.t-modify .w3-card {
  text-align: center;
  border-radius: 20px;
  font-size: 30px;
  background-color: rgb(238, 236, 236) !important;
  margin-bottom: 100px;
  position: relative;
  height: 700px;
  top: -100px;
}

.t-create .w3-card {
  text-align: center;
  border-radius: 20px;
  font-size: 40px;
  margin-left: 10%;
  background-color: rgb(238, 236, 236) !important;
  margin-bottom: 100px;
  height: 1050px;
  position: relative;
  width: 50%;
  top: -100px;
}

.t-invite .w3-card {
  text-align: left;
  border-radius: 20px;
  font-size: 40px;
  margin-left: 10%;
  background-color: rgb(238, 236, 236) !important;
  margin-bottom: 100px;
  height: auto;
  position: relative;
  width: 70%;
  top: -100px;
}

.t-next-invite-button {
  border-radius: 20px !important;
  outline: none !important;
  width: auto;
  color: white;
  background-color: rgb(12, 221, 221);
  margin-left: 60% !important;
  margin-right: auto !important;
  height: auto;
  font-size: smaller;
  margin-bottom: 15px !important;
  padding-left: 2%;
  padding-right: 2%;
  border: none;
}

.t-next-invite-button:hover {
  background-color: rgb(12, 221, 221) !important;
  outline: none;
}

.t-invite-button {
  border-radius: 20px !important;
  outline: none;
  width: 25%;
  color: white;
  background-color: rgb(12, 221, 221);
  margin-left: 65% !important;
  margin-right: auto !important;
  height: auto;
  font-size: smaller;
  margin-bottom: 50px !important;
}

.t-invite-button:hover {
  background-color: rgb(12, 221, 221) !important;
  outline: none;
}

.t-modify .t-check {
  margin-bottom: 50px;
  width: 50%;
  max-width: fit-content;
  height: auto;
}

.t-modify .t-wrong {
  margin-bottom: 50px;
  width: 25%;
  max-width: fit-content;
  height: auto;
}

.t-form {
  margin-right: auto;
  margin-left: auto;
  width: fit-content;
}

.t-modify .t-teampic {
  margin-bottom: 50px;
  width: 50%;
  max-width: fit-content;
  height: auto;
}

.t-form .w3-input {
  border-radius: 70px;
  padding: 5px;
  padding-left: 20px;
  margin-bottom: 90px;
}

.t-form .w3-input:focus {
  border-radius: 70px !important;
  outline: none;
}

.t-form-join {
  margin-right: auto;
  margin-left: auto;
  width: 50%;
}

.t-form-team {
  margin-right: auto;
  margin-left: auto;
  color: #fff;
}

.anchor-text{
  position: absolute;
  top: 36px;
  right: 0;
  @media only screen and (max-width: 1200px) {
    position: unset;
  }
}

.t-form-team .w3-input {
  border-radius: 70px;
  width: 100%;
  padding: 15px;
  padding-left: 20px;
  margin-bottom: 5%;
}

.t-form-team .w3-input:focus {
  border-radius: 70px !important;
  outline: none;
}

.t-form-join .w3-input {
  border-radius: 70px;
  width: 100%;
  padding-left: 20px;
  margin-bottom: 5%;
}

.t-form-join .w3-input:focus {
  border-radius: 70px !important;
  outline: none;
}

.t-form-home {
  margin-right: auto;
  margin-left: auto;
  width: 80%;
}

.t-form-home .w3-input {
  border-radius: 70px;
  width: 100%;
  outline: none !important;
  padding: 10px;
  padding-left: 20px;
  margin-bottom: 3% !important;
  font-weight: 900;
}

.t-form-home .w3-input.t-start-time {
  margin: 0px !important;
}

.t-form-home select.w3-input {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.t-form-home .rdtCounter .rdtBtn:hover {
  background: none;
}

.t-form-home .rdtCounter .rdtBtn {
  line-height: 50px;
}

.t-form-home .rdtCounterSeparator {
  position: relative;
  top: 6px;
}

.t-form-home .rdtPicker .rdtSwitch:hover {
  cursor: pointer;
  background: #eeeeee;
}

.t-login-form {
  margin-right: auto;
  margin-left: auto;
}

.t-login-form .w3-input {
  border-radius: 70px;
  width: 100%;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 18px;
}

.t-login-form .w3-input::placeholder {
  color: #6c757d;
}

.t-login-form .w3-input:focus .t-form-home .w3-input:focus {
  border-radius: 70px !important;
  outline: none;
}

.t-new {
  margin-top: -100px;
  margin-right: 5%;
}

.p-text {
  font-size: 30px;
  font-weight: 500;
  text-align: left;
  padding-left: 50px;
}

.t-next-button-invites {
  border-radius: 20px !important;
  outline: none !important;
  width: auto;
  color: white;
  background-color: rgb(12, 221, 221);
  margin-left: auto !important;
  margin-right: 30px;
  height: auto;
  font-size: x-large;
  font-weight: bold;
  padding: 6px 20px;
  margin-bottom: 15px !important;
  border: none;
  float: right;
}

.t-next-button-invites:hover {
  background-color: rgb(12, 221, 221) !important;
  outline: none;
  border: none;
}

.t-next-button {
  border-radius: 20px !important;
  outline: none !important;
  width: auto;
  color: white;
  background-image: linear-gradient(#01fdfd, #00b0ff);
  margin-left: auto !important;
  margin-right: auto !important;
  height: auto;
  font-weight: bold;
  padding: 6px 20px;
  font-size: 18px;
  border: none;
}

.t-next-join-button {
  border-radius: 20px !important;
  outline: none !important;
  width: auto;
  color: white;
  background-color: rgb(12, 221, 221);
  margin-left: auto !important;
  margin-right: auto !important;
  height: auto;
  font-weight: bold;
  padding-left: 50px;
  padding-right: 50px;
  border: none;
}

.t-next-join-button:hover {
  background-color: rgb(12, 221, 221) !important;
  outline: none;
  border: none;
}

.t-team-create-button {
  border-radius: 20px !important;
  outline: none !important;
  color: white;
  background-color: rgb(12, 221, 221);
  border: 1px rgb(12, 221, 221);
  margin-bottom: 50px !important;
  height: 10%;
  width: auto;
  font-size: x-large;
  font-weight: bold;
}

.t-team-create-button:hover {
  background-color: rgb(12, 221, 221) !important;
}

.t-next-button:hover {
  background-color: rgb(12, 221, 221) !important;
  background-image: linear-gradient(#00b0ff, #01fdfd);
  outline: none;
  border: none;
  color: white !important;
}

.t-create-home-button {
  border-radius: 20px !important;
  outline: none !important;
  width: auto;
  color: white;
  background-color: rgb(12, 221, 221);
  margin-left: 60% !important;
  margin-right: auto !important;
  height: 50px !important;
  font-size: larger;
  margin-bottom: 50px !important;
  padding-left: 5%;
  padding-right: 5%;
  border: none;
}

.t-create-home-button:hover {
  background-color: rgb(12, 221, 221) !important;
  outline: none;
  border: none;
}

.t-create-button {
  border-radius: 20px !important;
  outline: none;
  width: auto;
  color: white;
  background-color: rgb(12, 221, 221);
  margin-left: 60% !important;
  margin-right: auto !important;
  height: auto;
  font-size: smaller;
  margin-bottom: 50px !important;
  padding-left: 10%;
  padding-right: 10%;
}

.t-create-button:hover {
  background-color: rgb(12, 221, 221) !important;
  border: none !important;
  outline: none !important;
}

.t-pic-next-button {
  border-radius: 20px !important;
  outline: none;
  width: auto;
  color: white;
  background-image: linear-gradient(#01fdfd, #00b0ff);
  margin-left: 45% !important;
  margin-right: auto !important;
  height: auto;
  font-size: x-large;
  font-weight: bold;
  margin-bottom: 50px !important;
}

.t-pic-next-button:hover {
  background-color: rgb(12, 221, 221) !important;
}

.t-puzzle-paper {
  margin-left: 25px;
  text-align: left;
}

.t-border-radius-25 {
  border-radius: 25px !important;
}

.t-margin-bottom-0 {
  margin-bottom: 0 !important;
}

.t-margin-bottom-50 {
  margin-bottom: 50px !important;
}

.t-margin-bottom-100 {
  margin-bottom: 100px !important;
}

.t-margin-bottom-30 {
  margin-bottom: 30px !important;
}

.t-margin-cardside-2 {
  margin-left: 2%;
  margin-right: 2%;
  background-color: #f2f2f2;
  border-radius: 12px;
}

.t-fontSize-xxx-large {
  font-size: xx-large !important;
}

.t-fontSize-xx-large {
  font-size: x-large;
  font-weight: bolder;
  padding-top: 40px;
}
.t-fontSize-xx-large-tag {
  font-size: xx-large;
  font-weight: bolder;
  padding-right: 2%;
  padding-left: 2%;
  padding-top: 40px;
  text-align: left;
}

.t-fontSize-xx-large-link {
  font-size: 22px;
  font-weight: bolder;
  text-align: left;
  padding-left: 5%;
}

.t-fontSize-xx-large-invite {
  font-size: 28px;
  font-weight: bolder;
  padding-top: 40px;
  padding-left: 10px;
}

.t-invite-p-grey {
  font-weight: lighter;
  font-size: 20px;
  text-align: left;
  padding-left: 5%;
  color: grey;
}

.t-fontSize-x-large {
  font-size: x-large !important;
}

.t-puzzle-paper-left {
  background-color: transparent;
  position: relative !important;
  height: 200px !important;
  width: 70% !important;
  margin-left: 15% !important;
}

.t-puzzle-button {
  margin: 20px !important;
  border-radius: 25px !important;
}

.t-background-color-grey {
  background-color: lightgrey !important;
}

.t-login-modal {
  border-radius: 25px !important;
  background-color: #f2f2f2;
  align-items: center !important;
}

.t-modal-close {
  border-radius: 20px;
  top: 10px;
  right: 16px;
  cursor: pointer;
  color: darkgray;
}

.t-modal-close:hover {
  color: black;
}

.list {
  font-weight: lighter !important;
  position: relative;
  top: 14px;
}

.tabs-styles {
  font-size: 20px;
  font-weight: lighter !important;
}

.t-chat .h-styles {
  margin: 0px;
}

.t-chat .w3-card {
  text-align: left;
  border-radius: 0px;
  background-color: #eeecec !important;
  height: auto;
  position: absolute;
  width: 400px;
  bottom: 0px;
  right: 0px;
  border: 1px solid black;
  position: fixed;
}

.chat-c {
  /* border: 2px solid #dedede; */
  background-color: white;
  height: calc(100vh - 200px);
  margin: 0px 8px 8px 8px;
  overflow: auto;
  border: 1px solid black;
}

.accordion {
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: all 0.4s;
  border-radius: 10px;
  margin-top: 10px;
}

/* .active, .accordion:hover {
  outline: none;
  background-color: #ccc; 
} */

.panel {
  width: 140px;
  margin: 5px;
  overflow: hidden;
  position: relative;
}

.chatconv-left {
  font-weight: lighter;
  color: gray;
  text-align: left;
}

.chatconv-right {
  font-weight: lighter;
  color: gray;
  float: right;
}

.chat-send-button {
  border: none;
  outline: none !important;
  background-color: #eeecec;
}

.t-chat-text {
  width: 100%;
  border-radius: 15px !important;
  background-color: white;
  font-size: 14px;
  outline: none !important;
  border: none !important;
  position: relative;
  display: inline-flex;
}

.t-chat-text:focus {
  border-radius: 18 px !important;
  outline: none;
}

.t-naming-style {
  display: inline-flex;
  /* place-content: space-evenly; */
  margin-bottom: 30px;
}

.t-teampage-card {
  background-color: rgb(255, 255, 255) !important;
  text-align: center;
  min-height: 600px;
  font-size: 20px;
  border-radius: 20px !important;
  position: relative;
  padding-bottom: 80px;
  margin-bottom: 100px !important;
}

.t-teampage-card-create-game {
  background-color: #f2f2f2 !important;
  text-align: center;
  min-height: 600px;
  font-size: 20px;
  border-radius: 20px !important;
  position: relative;
  top: 50px;
  padding-bottom: 150px !important;
  margin-bottom: 100px !important;
}

.t-template-card-wrap {
  max-width: 1400px;
  margin: auto;
}

.t-teampage-card-question {
  background-color: #f2f2f2 !important;
  text-align: center;
  min-height: 600px;
  font-size: 20px;
  border-radius: 20px !important;
  top: 50px;
  padding-bottom: 80px;
}

.t-create-p {
  padding-top: 5%;
  font-weight: bold;
  font-size: 25px;
}

.t-creategame-button {
  border-radius: 20px !important;
  outline: none !important;
  border: none !important;
  width: auto;
  color: white;
  background-color: #0cdddd;
  height: auto;
  float: right;
  padding-left: 5%;
  padding-right: 5%;
}

.t-creategame-button :hover {
  background-color: rgb(12, 221, 221) !important;
  border: none !important;
  outline: none !important;
}

.grid-container {
  display: grid;
  grid-gap: 10px;
  background-color: pink;
  padding: 10px;
}

.t-team-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  margin-right: 20px;
  line-height: 40px;
  background: #16aa71;
  text-align-last: center;
  display: inline-table;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position: fixed;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background-color: #ebebeb !important;
  width: 400px;
  min-width: 400px;
}

.modal-title {
  color: #343a40;
  margin-bottom: 30px;
  font-weight: 600;
  margin-top: 30px;
}

.display-block {
  display: block;
}

.display-inline {
  display: inline;
}

.display-flex {
  display: flex;
}

.display-none {
  display: none;
}

.inputStyle {
  border-radius: 70px !important;
  outline: none;
}

.input-error {
  color: red;
  font-size: 15px;
  float: left;
  top: -15px;
  position: relative;
  margin-left: 15px;
}

.input-message-error {
  color: red;
  font-size: 15px;
  top: -15px;
  position: relative;
}

.input-error-msg {
  color: red;
  font-size: 15px;
  float: left;
  margin-left: 15px;
}

.input-success {
  color: green;
  font-size: 15px;
}

.input-success-invite {
  color: green;
  font-size: 16px;
  float: center;
}
.input-warning-invite {
  color: red;
  font-size: 16px;
  float: center;
}

.create-new {
  border-radius: 20px !important;
  border: none;
  outline: none;
  width: fit-content;
  padding-bottom: 10px;
  color: white;
  background-image: linear-gradient(#01fdfd, #00b0ff);
  font-size: x-large;
  font-weight: bold;
  top: 200px;
  position: relative;
}

.font-size-15 {
  font-size: 15px;
}

.password {
  color: black;
  cursor: pointer;
  font-size: medium;
  margin-top: 15px;
  float: left;
}

.t-password-button {
  border-radius: 20px !important;
  outline: none !important;
  width: auto;
  color: white;
  background-color: rgb(12, 221, 221);
  height: auto;
  font-size: medium;
  font-weight: bold;
  border: none;
}

.t-password-button:hover {
  background-color: rgb(12, 221, 221) !important;
  outline: none;
  border: none;
}

.tab {
  overflow: hidden;
  padding: 0px 45px;
  margin-top: 10px;
  border: black solid 1px;
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 8px 16px;
  transition: 0.3s;
  font-size: 15px;
  color: grey;
  border-radius: 8px 8px 0px 0px;
}

.tab button:hover {
  background-color: #ddd;
}

.tab button.active {
  background-color: #fff;
  color: #343a40;
}

.te-tabcontent {
  border-top: none;
}

@media only screen and (max-width: 400px) {
  .t-chat .w3-card {
    width: 330px;
  }
}

.land-button {
  border-style: unset;
  background-color: #f2f2f2;
  border-radius: 5px;
}

.land-button:hover {
  background-color: rgba(131, 18, 175, 0.747) !important;
  outline: none;
  color: white !important;
}

/* TeamEngage Revamp */

.te-title {
  @extend .font-secondary;
  font-weight: 600;
  font-size: 28px;
}

.te-text-secondary-32 {
  @extend .font-secondary;
  font-weight: 600;
  font-size: 32px;
}

.te-text-secondary-18 {
  @extend .font-secondary;
  font-weight: 600;
  font-size: 18px;
}

.te-title-mini {
  @extend .font-secondary;
  font-weight: 400;
  font-size: 1.5vmin;
}

.te-primary {
  @extend .font-primary;
}

.te-title-small {
  @extend .font-primary;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.02em;
}

.te-title-extra-small {
  @extend .font-primary;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.02em;
}


.te-text-primary-24 {
  @extend .font-primary;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.02em;
  outline: 0;
}

.te-text-primary-18 {
  @extend .font-primary;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.02em;
  outline: 0;
}

.te-text-primary-16 {
  @extend .font-primary;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  outline: 0;
}

.te-text-primary-14 {
  @extend .font-primary;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  outline: 0;
}

.te-text-large {
  @extend .font-primary;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 0.02em;
}

.te-text-large-bold {
  @extend .font-primary;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.02em;
}

.te-text-large-lite {
  @extend .font-primary;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.02em;
  opacity: 0.4;
}

.te-text {
  @extend .font-primary;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.02em;
}

.te-text-blue {
  color: #3c50e0 !important;
}

.te-text-lite {
  color: white !important;
}

.te-text-dark {
  color: #07003b !important;
}

.te-text-small {
  @extend .font-primary;
  font-size: 16px;
  line-height: 23px;
  font-weight: 400;
  letter-spacing: 0.02em;
}

.te-text-mini {
  @extend .font-primary;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.02em;
}

.te-text-primary-11 {
  @extend .font-primary;
  font-style: normal;
  font-size: 11px;
  outline: 0;
}

/* Bubblegum Sans Fonts */
.te-textcrusive-120 {
  @extend .font-cursive;
  font-weight: 400;
  font-size: 120px;
  letter-spacing: 0.02em;
  line-height: 240px;
  color: #ffffff;
}

.te-textcrusive-60 {
  @extend .font-cursive;
  font-weight: 400;
  font-size: 60px;
  letter-spacing: 0.02em;
  line-height: 80px;
  color: #ffffff;
}

.te-textcrusive-45 {
  @extend .font-cursive;
  font-weight: 400;
  font-size: 45px !important;
  letter-spacing: 0.02em;
  line-height: 70px;
  color: #ffffff;
}

.te-textcrusive-40 {
  @extend .font-cursive;
  font-weight: 400;
  font-size: 40px !important;
  letter-spacing: 0.02em;
  line-height: 70px;
  color: #ffffff;
}

.te-textcrusive-32 {
  @extend .font-cursive;
  font-weight: 400;
  font-size: 32px !important;
  line-height: 36px;
  letter-spacing: 0.02em;
}

.te-textcrusive-24 {
  @extend .font-cursive;
  font-weight: 400;
  font-size: 24px !important;
  line-height: 32px;
  letter-spacing: 0.02em;
}

.te-textcrusive-20 {
  @extend .font-cursive;
  font-weight: 400;
  font-size: 20px !important;
  line-height: 28px;
  letter-spacing: 0.02em;
}

.te-textcrusive-18 {
  @extend .font-cursive;
  font-weight: 500;
  font-size: 18px !important;
  line-height: 32px;
  letter-spacing: 0.02em;
}

.te-game-text-large {
  @extend .font-cursive;
  font-style: normal;
  font-weight: "500";
  font-size: 24px;
  line-height: 70px;
  color: #ffffff;
}

.te-game-text {
  @extend .font-cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 70px;
  color: #ffffff;
}

.te-game-text-small {
  @extend .font-cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #ffffff;
}

.te-event-card-title {
  font-size: 18px;
  line-height: 24px;
  font-family: Raleway;
  font-weight: 700;
  color: #ffffff;
}
.te-event-card-full {
  @media only screen and (max-width: 1400px) {
      width: 100% !important;
  }
}
.time-stamp {
  color: #787a92;
  font-size: 11px;
}

.te-game-text-xs {
  @extend .font-cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
}

.te-game-text-content-14 {
  font-size: 14px;
}

/* Work Sans Fonts */
.te-work-sans {
  @extend .font-sans;
}

.te-text-work-12 {
  @extend .font-sans;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.te-text-work-14 {
  @extend .font-sans;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.te-text-work-16 {
  @extend .font-sans;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.te-text-work-18 {
  @extend .font-sans;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.te-text-work-22 {
  @extend .font-sans;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
}

.te-text-paycard {
  @extend .font-sans;
  font-weight: 400;
  font-size: 1.6vmin;
  line-height: 20px;
}

.te-text-paycard-small {
  @extend .font-sans;
  font-weight: 400;
  font-size: 1.3vmin;
  line-height: 18px;
}

.te-game-text-content {
  @extend .font-sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.te-game-text-content-xs {
  @extend .font-sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #8e90a8;
}

.te-text-bolder {
  font-weight: 700;
}

.te-text-yellow {
  color: #faff19;
}

.te-text-white {
  color: white;
}

.te-text-small-blue {
  color: #3c50e0 !important;
  font-weight: 600;
  cursor: pointer;
}

.te-text-mini-blue {
  color: #3c50e0 !important;
  font-weight: 400;
  cursor: pointer;
}

.te-text-error {
  @extend .font-primary;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.02em;
  color: #b30000 !important;
}

.te-text-success {
  @extend .font-primary;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.02em;
  color: #2a8000 !important;
}

.te-text-success-large {
  @extend .font-primary;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.02em;
  color: #2a8000 !important;
}

.te-lavender {
  background: #edf0ff !important;
  padding: 0vmin 0vmin 5vmin 0vmin;
  border-left: none;
  label{
    display: inline-block;
    margin:10px 0px 5px 0px;
  }
}

.te-lavender-dark{
  background: none !important;
  background-image: linear-gradient(246deg, #262d6e, #181c43, #181c43) !important;
  padding: 8px 0px;

  @media only screen and (min-width: 601px) {
    border-radius: 30px;
    border-left: solid $primaryBgColor 5px;
    min-height: 65vh;
    padding: 12px 16px;
  }
}

.te-offwhite {
  background-color: #f6f7fa !important;
}

.te-blue {
  background-color: #3c50e0;
}

.te-button-blue {
  color: white !important;
  background-color: #3c50e0 !important;
  border: 2px solid #3c50e0;
  border-color: #3c50e0 !important;
  padding-left: 25px;
  padding-right: 25px;
  outline: none !important;
  cursor: pointer;
}

.te-button-blue:hover {
  color: white !important;
  background-color: #1f2c87 !important;
  border-color: #1f2c87 !important;
  padding-left: 25px;
  padding-right: 25px;
}

.te-button-blue-inactive {
  border-color: none !important;
  color: white !important;
  background-color: #abb2fb !important;
  padding-left: 25px;
  padding-right: 25px;
  cursor: not-allowed;
  pointer-events: none;
  border: none;
}

.te-button-transparent {
  color: #3c50e0 !important;
  background-color: transparent !important;
  border: 1px solid #3c50e0 !important;
  padding-left: 25px;
  padding-right: 25px;
  outline: none !important;
}

.te-button-transparent:hover {
  color: #1f2c87 !important;
  background-color: #f7f8fc !important;
  border: 1px solid #1f2c87 !important;
  padding-left: 25px;
  padding-right: 25px;
}

.te-button-transparent-dark {
  color: white !important;
  background-color: transparent !important;
  padding-left: 25px;
  padding-right: 25px;
  border: 1px solid white;
  box-sizing: border-box;
  outline: none !important;
}

.te-button-transparent-dark:hover {
  color: #b4bcf5 !important;
  border: 1px solid #b4bcf5 !important;
  background-color: transparent !important;
}

.resume-btn{
  border: $primaryColor2 solid 2px;
  color: $primaryColor2;
  background: $primaryColor4;
}

.te-button-white {
  color: #3c50e0 !important;
  background-color: white !important;
  border-color: #3c50e0 !important;
  padding-left: 25px;
  padding-right: 25px;
  outline: none !important;
}

.te-button-white:hover {
  color: #0a1142 !important;
  background-color: #dcdcdc !important;
  border-color: #0a1142 !important;
  padding-left: 25px;
  padding-right: 25px;
}

.te-box-white-alt {
  color: #0a1142 !important;
  background-color: white !important;
  border-color: #0a1142 !important;
}

.te-box-white-alt:hover {
  color: #0a1142 !important;
  background-color: #f6b75c !important;
  border-color: #0a1142 !important;
}

.te-cursor-pointer {
  cursor: pointer !important;
}

.te-cursor-default {
  cursor: default !important;
}

.te-text-paycard-no {
  font-weight: 400;
  font-size: 5vmin;
  line-height: 30px;
  letter-spacing: 0.045em;
}

.te-text-paycard-no-large {
  font-weight: 400;
  font-size: 10vmin;
  line-height: 70px;
  letter-spacing: 0.045em;
}

.te-pass-wrapper {
  position: relative;
  display: flex;
}

.te-eye-icon {
  position: absolute;
  top: 38%;
  right: 5%;
}

.te-pvt-icon {
  position: absolute;
  top: 12px;
  right: 12px;
}

.te-icon-text {
  position: absolute;
  top: 12px;
  right: 38px;
  font-size: 12px;
  color: #838383;
}

.te-avoid-clicks {
  pointer-events: none;
}

.margin-top-100 {
  margin-top: 100px !important;
}
.margin-top-20 {
  margin-top: 20px !important;
}
.margin-top-30 {
  margin-top: 30px !important;
}
.margin-top-45 {
  margin-top: 30px !important;
}
.margin-top-60 {
  margin-top: 60px !important;
}

.te-selected {
  // color: #3C50E0 !important;
  color: lightskyblue !important;
  // text-decoration: underline;
  // text-underline-position: under;
  // text-decoration-color: #3C50E0;
}
.te-logo {
  height: 50px !important;
  padding: 8px 16px;
  position: relative;

  @media only screen and (max-width: 800px) {
    width: 140px !important;
    bottom: 1px;
  }
}

.te-tab-button {
  border-radius: 30px 30px 4px 30px;
  padding: 5px 12px;
  border: 1px solid #1f4068;
  outline: none !important;
  margin-inline: 2px;
  background: transparent;

  &.te-active {
    background: #1f4068;
  }
}

.te-round-chips {
  width: fit-content;
  white-space: nowrap;
  width: 100px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 2px;
  padding: 0px 5px;
  @media only screen and (max-width: 800px) {
    width: 80px;
    padding: 0px 5px;
  }
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;

  &.w3-dropdown-hover {
    &:hover {
      overflow: auto;
    }
  }
}

.yellow {
  background-color: #fabb2e;
}

.orange {
  background-color: #eb6945;
}

.pink {
  background-color: #c71585;
}

.red {
  background-color: #f35588;
}

.teal {
  background-color: #64ffda;
}

.cyan {
  background-color: #3fc5f0;
}

.pastelYellow {
  background-color: #fdddaa;
}

.pastelBlue {
  background-color: #b7dcf4;
}

.pastelRed {
  background-color: #f285af;
}

.bg-one {
  background-image: url(../images/cardBG1.png) !important;
}

.bg-two {
  background-image: url(../images/cardBG2.png) !important;
}

.bg-three {
  background-image: url(../images/cardBG3.png) !important;
}

.bg-four {
  background-image: url(../images/cardBG3.png) !important;
}

.text-lite {
  color: white;
}

.text-dark {
  color: black;
}

.text-dark-blue {
  color: #0a1142 !important;
}

.te-option-selected {
  background-color: #3985ff !important;
}

.te-option-correct{
  background-color: #169c33 !important;
}

.te-option-unselected {
  background-color: rgba(255, 255, 255, 0.1);
  &:hover{
    background-color: rgba(255, 255, 255, 0.15);
  }
}

.te-mcq-options .w3-button {
  // padding: 0px !important;
}

.te-mcq-options label {
  padding: 8px 16px 16px 16px !important;
  margin: 0px !important;
  color: #666;
}

.MuiIconButton-root {
  display: none !important;
  flex: 0 0 auto !important;
  color: rgba(0, 0, 0, 0.54) !important;
  padding: 12px !important;
  overflow: visible !important;
  font-size: 1.5rem !important;
  text-align: center !important;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 50% !important;
}

.MuiTypography-body1 {
  @extend .font-cursive;
  font-weight: 400 !important;
  font-size: 24px !important;
  line-height: 32px !important;
  letter-spacing: 0.02em !important;
  padding: 10px 20px 0px 20px !important;
}

.MuiPaper-root {
  @extend .font-cursive;
  font-weight: 400 !important;
  font-size: 24px !important;
  line-height: 32px !important;
  letter-spacing: 0.02em !important;
}

.MuiGrid-container {
  & > div {
    padding: 5px !important;
    & > div > div {
      padding: 0px !important;
      span {
        left: 15% !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
      }
    }
  }
}

.te-text-wrap {
  white-space: nowrap;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.te-text-wrap-80 {
  white-space: nowrap;
  width: 80%;
  margin: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.te-text-wrap-240 {
  white-space: nowrap;
  max-width: 240px;
  margin: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.empty-grid {
  height: 1px;
}

.te-home-intro-container {
  padding-top: calc(min(15vw, 8vh));
  @media only screen and (max-width: 900px) {
    padding-top: 10px;
  }
}

.te-home-intro {
  @extend .font-secondary;
  font-size: clamp(15px, 8vw, 60px);
  font-weight: 600;
  line-height: clamp(18px, 9vw, 65px);
}

.te-home-title {
  @extend .font-secondary;
  font-size: calc(min(10vw, 60px));
  font-weight: 600;
  line-height: calc(min(12vw, 70px));
}
@media only screen and (max-width: 993px) {
  .te-home-title {
    text-align: left !important;
    margin: 0;
  }
}
.black-stroke {
  -webkit-text-stroke: 1.2px black;
  color: transparent;
}

.te-te-light {
  @extend .font-secondary;
  cursor: default;
  font-size: calc(min(10vw, 100px));
  font-weight: 600;
  color: rgba(255, 255, 255, 0.47);
  -webkit-text-stroke: 1px white;
}

.input-no-style {
  padding: 0px;
  background: none;
  margin: 0px;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &:focus {
    outline: none;
  }
}

.te-text-left {
  text-align: left !important;
}

.te-invite-list {
  min-height: 10px;
  th,
  td {
    border-bottom: 1px solid #ddd;
    text-align: left;
    padding: 10px 15px 10px;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  .te-table-container {
    background-color: rgba(255, 255, 255, 0.04);
    border-radius: 15px;
    padding: 0px 15px 30px;
    margin-bottom: 20px;
    color: #fff;
    
    @media only screen and (min-width: 993px) {
      width: 70%;
    }
  }
  .te-status-invited {
    color: white;
    border-radius: 15px;
    background-color: #118617;
    padding: 5px 10px 5px 10px;
  }

  .te-status-added {
    color: white;
    border-radius: 15px;
    background-color: #3c68e0;
    padding: 5px 10px 5px 10px;
  }
  .te-status-joined {
    color: white;
    border-radius: 15px;
    background-color: #29ce6b;
    padding: 5px 10px 5px 10px;
  }
  .te-status-declined {
    color: white;
    border-radius: 15px;
    background-color: #ed653b;
    padding: 5px 10px 5px 10px;
  }
  .te-status-error {
    color: #3c50e0;
    text-decoration: underline;
    text-underline-offset: 5px;
    border-radius: 15px;
    padding: 5px 0px 5px 0px;
  }
  .te-status-error:hover {
    color: black;
  }
}

.te-groups-lite {
  width: fit-content;
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
  }

  .select-wrap {
    width: fit-content;
    // margin: 15px 0px;
    position: relative;
    user-select: none;
    color: black;
    border-radius: 5px;
    box-shadow: 0px 0px 35px 1px rgba(60, 80, 224, 0.16);

    .selected-group {
      background: #fff;
      border-radius: 5px;
      position: relative;
      cursor: pointer;
      li {
        padding: 4px 4px;
      }
    }
    .select_ul {
      position: absolute;
      top: 50px;
      left: 0;
      width: max-content;
      background: #fff;
      border-radius: 5px;
      box-shadow: 0px 0px 35px 1px rgba(60, 80, 224, 0.16);
      display: none;
      z-index: 20;
      li {
        padding: 4px 6px;
        cursor: pointer;
      }
      li:hover {
        background: #d3ddff;
      }
      li:first-child:hover {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      li:last-child:hover {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }

  .select-wrap.active .select_ul {
    display: block;
  }

  .select-wrap.active .selected-group:before {
    top: 17px;
    transform: rotate(-225deg);
  }

  img {
    height: 30px;
    width: 30px;
    object-fit: contain;
  }
}

.te-groups-dark {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
  }

  .select-wrap {
    width: 200px;
    margin: 15px 0px;
    position: relative;
    user-select: none;
    color: black;
    border-radius: 5px;
    box-shadow: 0px 0px 35px 1px rgba(60, 80, 224, 0.16);

    .selected-group {
      background: #1f4068;
      color: #fff;
      border-radius: 5px;
      position: relative;
      cursor: pointer;
      li {
        padding: 4px 6px;
      }
    }

    .select_ul {
      position: absolute;
      top: 50px;
      left: 0;
      width: max-content;
      min-width: 100%;
      background: #1f4068;
      color: #fff;
      border-radius: 5px;
      box-shadow: 0px 0px 35px 1px rgba(60, 80, 224, 0.16);
      display: none;
      z-index: 20;
      li {
        padding: 4px 6px;
        cursor: pointer;
      }
      li:hover {
        background: #152c48;
      }
      li:first-child:hover {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      li:last-child:hover {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }

  .select-wrap.active .select_ul {
    display: block;
  }

  .select-wrap.active .selected-group:before {
    top: 17px;
    transform: rotate(-225deg);
  }

  img {
    height: 30px;
    width: 30px;
    object-fit: cover;
    margin-right: 5px;
  }
}

.te-prize {
  width: 100%;
  .te-width-35 {
    width: 35%;
  }
  .te-prize-row {
    margin: 5px;
  }
  .te-prize-textarea {
    border: 1px solid #b7cae0;
    border-radius: 10px;
    height: 60px;
    width: 100%;
    padding: 10px;
    margin: 5px 0px;
  }
}

.te-clipboard {
  position: absolute;
  background: #1f4068;
  font-size: 12px;
  padding: 0px 6px;
  margin: 6px 0px;
  border-radius: 5px 5px 5px 5px;
  border: 1px solid #4caf5071;
}

.te-clipboard-input {
  position: absolute;
  background: #1f4068;
  color: #1f4068;
  height: 3px;
  width: 10px;
  font-size: 2px;
  border: 1px solid #1f4068;
  opacity: 0;
  cursor: default;
}

.te-prize-ribbon {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 120px;
  height: 120px;
  overflow: hidden;
  span {
    width: 225px;
    padding: 1px;
    height: 30px;
    background-image: linear-gradient(
      to right,
      #462523 0,
      #cb9b51 28%,
      #f6e27a 45%,
      #f6f2c0 50%,
      #f6e27a 54%,
      #cb9b51 76%,
      #462523 100%
    );
    display: block;
    position: absolute;
    top: 40px;
    left: -36.5px;
    transform: rotate(45deg);
    text-align: center;
    color: #3a1414;
    font-weight: 600;
  }
}

.te-prize-ribbon::after {
  content: "";
  position: absolute;
  border-top: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 3px solid #462523d5;
  border-left: 3px solid #462523;
  top: 0;
  left: 0;
}

.te-prize-ribbon::before {
  content: "";
  position: absolute;
  border-top: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 3px solid #cb9b51;
  border-left: 3px solid #cb9b51;
  bottom: 0;
  right: 0;
}

.info-icon {
  background-image: url("../images/infoIcon.png");
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
}


.te-step {
  cursor: pointer;
  &.te-step-disabled {
    cursor: not-allowed !important;
  }
}

.slide-buttons{
  gap: 8px;
  margin-right: 16px;
  @media only screen and (min-width: 601px) {
    margin-right: 5px;
  }
  div{
    color: white;
    border: white 2px solid;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    padding-top: 2px;
  }
}

.youtube-container{
  iframe{
    max-width: 100%;
  }
}

.select-game-card-description {
  padding-top: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.active-events-game-card-description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    white-space: pre-wrap;
    font-size: 14px;
    color: #9cc2ff;
    margin-bottom: 18px;
    margin-top: 10px;
}
