@import "./w3.css";
@import "./game.scss";
@import "./common.scss";
@import "./variables.scss";

$headerHeight: 61px;
.lb {
  img {
    width: 200px;
    height: 300px;
    object-fit: cover;
  }
}

.input-radius {
  border-radius: 30px;
}
body {
  // background:url('https://d1xp9ts0bx8s2q.cloudfront.net/vow/game/img/patterns/light_21.jpg');
  background-size: 100% auto;
  font-family: "Raleway", "sans-serif";
  background-image: unset !important;
  background-attachment: unset !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway", "sans-serif";
}

input {
  outline: none;
}

button {
  outline: 0;
  &:hover {
    outline: 0;
  }
}

.te-button-primary {
  background: linear-gradient(to right, $primaryColor1, $primaryColor2);
  color: #fff !important;
  font-family: "Raleway","sans-serif";
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 8px 16px;

  &:hover {
    color: #fff !important;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(to right, $primaryColor1, $primaryColor2);
  }
  
  &[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.rectangle {
  // width: 1160px;
  display: flex;
  justify-content: center;
  height: 59px;
  // margin: 29px 1px 30px 0;
  // padding: 20px 378px 20px 390px;
  border-radius: 10px;
  border: solid 1px rgba(255, 255, 255, 0.16);
  background-color: rgba(255, 255, 255, 0.04);
  .no-data-message {
    font-family: 'Raleway';
    color: #9cc2ff;
    font-size: 16px;
  }
}

.fun-event-suggestion-card {
  // width: 367px;
  height: 225px;
  margin: 30px 30px 5px 0;
  padding: 56px 95px 30px 35px;
  border-radius: 10px;
  border: solid 1px rgba(100, 65, 162, 0.53);
  cursor: pointer;
}
.release-party {
    background-image: linear-gradient(to right, #1b2866, #2a4499);
    span {
      color: #4aa0f3;
    }
}

.birthday {
  background-image: linear-gradient(to right, #2c1b62, #5b2789);
  span {
    color: #e2488d;
  }
}

.fresher {
  background-image: linear-gradient(to right, #203665, #26838f);
  span {
    color: #44d7d2;
  }
}

.others {
  background-image: linear-gradient(to right, #242855, #242855);
  span {
    color: #9cc2ff;
  }
}

.card-content {
  // width: 237px;
  height: 84px;
  margin: 0 0 10px;
  font-family: Raleway;
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(229, 73, 143, 0.99);
  .text-style-bold {
    font-weight: 800;
  }
}

.birthday-oval-bar {
  cursor: pointer;
  border: solid 1px rgba(100, 65, 162, 0.53);
  background-image: linear-gradient(to right, #2c1b62, #5b2789);
  margin: 0px 30px 5px 0;
    span {
      color: #e2488d;
      font-family: Raleway;
      font-size: 16px;
    }
}

.release-party-oval-bar {
  cursor: pointer;
  border: solid 1px rgba(72, 65, 162, 0.53);
  background-image: linear-gradient(to right, #1b2866, #2a4499);
  margin: 0px 30px 5px 0;
    span {
      color: #4aa0f3;
      font-family: Raleway;
      font-size: 16px;
    }
}

.fresher-welcome-oval-bar {
  cursor: pointer;
  border: solid 1px rgba(34, 82, 116, 1);
  background-image: linear-gradient(to right, #203665, #26838f);
  margin: 0px 30px 5px 0;
    span {
      color: #44d7d2;
      font-family: Raleway;
      font-size: 16px;
    }
}

.other-events-oval-bar {
  cursor: pointer;
  border: solid 1px rgba(72, 65, 162, 0.53);
  background-color: rgba(255, 255, 255, 0.04);
  margin: 0px 30px 5px 0;
    span {
      color: #9cc2ff;
      font-family: Raleway;
      font-size: 16px;
    }
}


.te-button-secondary {
  border: solid 1px #fff;
  background: transparent;
  color: #fff !important;
  font-family: "Raleway","sans-serif";
  cursor: pointer;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #fff !important;
    background-color: rgba(255, 255, 255, 0.15);
  }

  &[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.te-button-secondary-2 {
  border: solid 1px #f14f85;
  color: #f14f85;
  background-color: #fff;
  margin-right: 20px;
  padding: 8px 25px;
  cursor: pointer;
  font-weight: 600;

  &:hover {
    color: #f14f85;
    background-color: rgba(241, 79, 133, 0.15);
  }
}

.te-button-new-event {
  background: none;
  color: $primaryColor2;
  border: 2px solid $primaryColor2;
  padding-left: 25px;
  padding-right: 25px;
  
  @media only screen and (min-width: 601px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  
  &:hover{
    background: $primaryColor2 !important;
    color: #fff !important;
  }
  @media only screen and (max-width: 600px) {
    padding: 6px 10px 6px 10px;
    margin-top: 6px;
  }
}

.te-button-share-event {
  background: none;
  color: #fff;
  border: 1px solid #fff;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-engage-app {
  .app-bg-image {
    display: block;
    z-index: 10;
    margin: 0px;
    background-size: auto;
    background-repeat: no-repeat;
    .header {
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 5%;
      padding-left: 5%;
      border-radius: 0px 0px 10px 10px;
      padding-top: 2px;
      padding-bottom: 2px;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 0;
      width: 100%;

      background: rgb(2, 0, 36);
      background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(3, 3, 6, 0.98) 10%,
        rgba(0, 0, 0, 0.8) 50%,
        rgba(1, 1, 1, 0.98) 90%,
        rgba(0, 0, 0, 1) 100%
      );

      @media only screen and (max-width: 400px) {
        flex-direction: column;
        padding: 0;
      }
    }
  }

  .app-bg-land {
    display: block;
    z-index: 10;
    margin: 0px;
    background-color: white;
    background-size: auto;
    background-repeat: no-repeat;

    .header {
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 5%;
      padding-left: 5%;
      padding-top: 15px;
      width: 100%;
      top: 0px;
      left: 0px;

      @media only screen and (max-width: 400px) {
        flex-direction: column;
        padding: 0;
      }
    }
  }

  .main-div {
    min-height: 100vh;
    @media only screen and (max-width: 400px) {
      padding: 0 !important;
    }
  }
  // .w3-modal {
  //   z-index: 10;
  // }
}

.preloader-container {
  margin: auto;
  width: 150px;
  padding-top: 25vh;
}

.home-loader {
  margin: auto;
  width: 150px;
  padding-top: 50vh;
  transform: translateY(-30%);
  margin: auto;
}

.team-engage-home-new {
  display: flex;
  flex-direction: column;
  padding: 5%;
  padding-top: 0;
  overflow-x: hidden;
  section {
    margin: 8vw;
    margin-top: clamp(10px, 10vw, 60px);
    margin-bottom: clamp(10px, 10vw, 60px);
    .title-container {
      text-align: center;
      .te-home-title {
        width: 650px;
        display: inline-block;
        text-align: center;
      }
    }
    @media only screen and (max-width: 993px) {
      margin-left: 0;
      margin-right: 0;
      .title-container {
        .te-home-title {
          width: 100%;
        }
        .te-home-title-padding1,
        .te-home-title-padding2,
        .te-home-title-padding3 {
          padding-left: 0;
          margin-left: 0;
        }
      }
    }
  }
  .te-intro-section {
    min-height: 90vh;
    .homepage-cards {
      position: relative;
      width: auto;
      max-width: 650px;
      height: calc(min(60vw, 60vh));
      .homepage-card1,
      .homepage-card2,
      .homepage-card3 {
        width: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transition: 0.3s ease-in-out;
      }
      .homepage-card3 {
        left: 35%;
        transform: translate(-50%, -50%) rotate(-6.39deg);
      }
      .homepage-card2 {
        z-index: 4;
        transform: translate(-50%, -50%);
      }
      .homepage-card1 {
        left: 65%;
        transform: translate(-50%, -50%) rotate(7.25deg);
      }
    }
    @media only screen and (max-width: 993px) {
      height: auto;
    }
    .homepage-cards:hover {
      .homepage-card3 {
        top: 50%;
        left: 20%;
        transform: translate(-50%, -50%) rotate(-6.39deg);
      }
      .homepage-card2 {
        top: 45%;
        z-index: 4;
        transform: translate(-50%, -50%);
      }
      .homepage-card1 {
        top: 50%;
        left: 80%;
        transform: translate(-50%, -50%) rotate(7.25deg);
      }
    }
  }
  .te-carousel {
    display: flex;
    margin-bottom: 50px;
    margin-top: 25px;
    min-height: 70%;
    .arrow-buttons {
      width: 100%;
      display: flex;
      height: 40px;
      margin-top: 65px;
      .leftBtn {
        border-radius: 7px 0px 0px 7px;
        padding: 5px;
        padding-left: 13px;
        padding-right: 13px;
      }
      .rightBtn {
        border-radius: 0px 7px 7px 0px;
        padding: 5px;
        padding-left: 13px;
        padding-right: 13px;
      }
    }
    .slider {
      width: 105%;
      position: relative;
      .slide-content {
        margin-left: clamp(10px, 5vw, 60px);
        margin-bottom: 20px;
        height: calc(min(45vw, 410px));
        padding: 5%;
        display: flex;
        flex-direction: column;
        transition: ease-in-out 0.5s;
        transform-origin: 0% 100%;
        transform: scale(0.8);
        .organisation-name {
          // font-family: Poppins, Arial, Helvetica, sans-serif;
          font-family: "Urbanist", sans-serif;
          font-weight: 500;
          font-size: clamp(12px, 1.5vw, 15px);
        }
        .user-comments {
          // font-family: Raleway, Arial, Helvetica, sans-serif;
          font-family: "Urbanist", sans-serif;
          font-style: italic;
          font-size: clamp(10px, 1.5vw, 15px);
        }
        .username {
          // font-family: Poppins, Arial, Helvetica, sans-serif;
          font-family: "Urbanist", sans-serif;
          font-size: clamp(10px, 1.2vw, 15px);
        }
        .user-destignation {
          // font-family: Work Sans, Arial, Helvetica, sans-serif;
          font-family: "Urbanist", sans-serif;
          font-size: clamp(8px, 1.1vw, 15px);
        }

        .profile-img {
          position: absolute;
          top: -40px;
          left: -10px;
          width: 160px;
          height: 160px;
          object-fit: cover;
          clip-path: path(
            "M 118.0868 142.5 H 37.5994 C 32.2574 142.5 27.5585 138.969 26.0729 133.8375 L 0.755 46.3927 C -0.6074 41.6872 1.0273 36.6259 4.8848 33.6062 L 44.4003 2.6734 C 47.7094 0.083 52.1323 -0.5876 56.0605 0.9054 L 145.1048 34.7483 C 150.8647 36.9376 154.0305 43.1341 152.4292 49.0845 L 129.6743 133.6192 C 128.2643 138.8588 123.513 142.5 118.0868 142.5 Z"
          );
        }

        .profile-logo {
          margin-top: 10px;
          margin-bottom: 50px;
          width: clamp(30px, 15vw, 100px);
          align-self: flex-end;
          padding-bottom: 20px;
        }
      }
      .slick-dots {
        left: -10vw;
        li {
          button::before {
            color: transparent;
            width: 10px;
            background-color: transparent;
            opacity: 1;
            height: 10px;
            border: 1px #3c50e0 solid;
            border-radius: 50%;
          }
        }
        .slick-active {
          button::before {
            color: transparent;
            width: 10px;
            background-color: #3c50e0;
            opacity: 1;
            height: 10px;
            border: 1px #3c50e0 solid;
            border-radius: 50%;
          }
        }
      }
      @media only screen and (max-width: 993px) {
        .slide-content {
          height: clamp(270px, 35vw, 450px);
          .profile-img {
            transform: scale(0.8);
            top: -50px;
            left: -27px;
          }
          .profile-logo {
            margin-bottom: 15px;
          }
        }
        width: 150%;
      }
      @media only screen and (max-width: 600px) {
        .slide-content {
          height: clamp(260px, 30vw, 450px);
          .profile-img {
            transform: scale(0.6);
            top: -58px;
            left: -38px;
          }
          .profile-logo {
            margin-bottom: 10px;
          }
        }
        width: 150%;
      }
      .slick-current div {
        .slide-content {
          transition: ease-in-out 0.5s;
          transform: scale(1);
          filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.1));
        }
      }
    }
    @media only screen and (max-width: 993px) {
      display: flex;
      flex-direction: column;
      .arrow-buttons {
        margin-top: 0;
      }
    }
  }
  .hover-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 30px;
    margin: -30px 0 0 0;
    .card-steps {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 100%;
      .card-text {
        width: 90%;
        padding-top: 70%;
        padding-left: 10%;
        // font-family: Raleway, Arial, Helvetica, sans-serif;
          font-family: "Urbanist", sans-serif;
        font-weight: 200;
      }
    }
  }
  .hover-cards > * {
    margin-top: 30px;
  }
  .price-cards {
    width: 100%;
    height: 450px;
    position: relative;
    color: #3c50e0;
    img {
      transform: scale(0.6);
      margin-right: 10px;
      filter: drop-shadow(0px 7px 8px rgba(22, 29, 94, 0.219));
    }

    .payment-button {
      box-shadow: black;
      filter: drop-shadow(8px 8px 8px rgba(15, 34, 101, 0.2));
      padding-left: 10px;
      height: 50px;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      background: linear-gradient(180deg, #5767f2 0%, #1c209e 100%);
      padding-right: 20px;
      border: 1px solid #9db6e7;
      .wallet-icon {
        background: linear-gradient(180deg, #fefeff 0%, #dce2ff 100%);
        margin: 4px;
        margin-left: 0px;
        margin-right: 20px;
        img {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
    .payment-button-white {
      background: linear-gradient(180deg, #fefeff 0%, #dce2ff 100%);
      border: 1px solid #ffffff;
      .wallet-icon {
        background: linear-gradient(180deg, #5767f2 0%, #1c209e 100%);
        img {
          filter: invert(1);
        }
      }
      p {
        color: #4753db;
      }
    }
    .price-card-1 {
      width: 270px;
      height: 350px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-151%, -50%);
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.42) 0%,
        rgba(192, 188, 237, 0.12) 100%,
        rgba(216, 215, 235, 0.29) 100%
      );
      display: flex;
      flex-direction: column;
      .price {
        width: 100%;
        height: 35%;
        background: #d7d4f4;
        padding-top: 13px;
      }
      p {
        margin: 8px;
        margin-bottom: 0;
      }
      button {
        margin-top: 10px;
      }
    }
    .price-card-2 {
      width: 280px;
      height: 415px;
      position: absolute;
      left: 50%;
      top: 51%;
      transform: translate(-50%, -50%);
      z-index: 5;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 16px 46px 6px rgba(30, 10, 86, 0.2);
      background-color: #f0f5ff;
      .price {
        width: 100%;
        height: 35%;
        background: #e5eeff;
        padding-top: 20px;
      }
      p {
        margin: 8px;
        margin-bottom: 0;
      }
      button {
        margin-top: 25px;
      }
    }
    .price-card-3 {
      width: 270px;
      height: 395px;
      position: absolute;
      left: 50%;
      top: 50%;
      display: flex;
      flex-direction: column;
      background: linear-gradient(180deg, #c2def0 0%, rgba(194, 222, 240, 0.32) 100%);
      transform: translate(51%, -56%);
      z-index: 0;
      .price {
        width: 100%;
        height: 35%;
        background: #c1d7ef;
        padding-top: 20px;
      }
      p {
        margin: 8px;
        margin-bottom: 0;
      }
      button {
        margin-top: 25px;
      }
    }
    @media only screen and (max-width: 865px) {
      height: auto;
      .price-card-1,
      .price-card-2,
      .price-card-3 {
        position: static;
        transform: scale(0.92);
        margin: auto;
      }
    }
  }
  .payment-form {
    margin: auto;
    width: auto;
    max-width: max-content;
    input {
      padding: 12px;
      background: #e0e0fa;
      margin-bottom: 10px;
      padding-left: 20px;
    }
    button {
      margin-bottom: 10px;
    }
  }
  .contact-form {
    width: 54vw;
    min-height: 30vh;
    margin: auto;
    background: #dbedfd;
    padding: 2vw;
    @media only screen and (max-width: 800px) {
      width: 90vw;
      padding: 15px;
    }
    div {
      width: 100%;
      text-align: left !important;
    }
    div input {
      padding: 12px 24px;
      background: #f2f6ff;
      margin: 8px 0px;
      border: 0px;
    }
    section {
      margin: 0px !important;
      padding: 0px 30px;
    }
    section p,
    header {
      text-align: left !important;
    }
    textarea {
      padding: 12px 24px;
      background: #f2f6ff;
      margin: 8px 0px;
      border: 0px;
      min-height: 160px;
      resize: none;
    }
    .split {
      display: flex;
      flex-direction: row;
      @media only screen and (max-width: 800px) {
        flex-direction: column;
      }
    }
    .split > * + * {
      margin-left: 10px;
      @media only screen and (max-width: 800px) {
        margin-left: 0px;
      }
    }
    button {
      @media only screen and (max-width: 600px) {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}

.team-engage-home {
  .te-intro-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 200px;
    p {
      margin-top: 20px;
      color: grey;
      font-size: 1.5rem;
    }
  }
  .te-fam-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    margin-left: 10%;
    p {
      margin-top: 20px;
      color: grey;
      font-size: 1.5rem;
    }
  }

  .te-fam-header {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .te-create-game {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 10px;
    margin-top: 60px;
    max-width: 400px;

    h3 {
      align-self: center;
      font-weight: bold;
      margin-bottom: 30px;
    }

    form {
      display: flex;
      flex-direction: column;
    }

    input {
      height: 50px;
      font-size: 1.3rem;
      padding: 8px 16px;
      border: none;
      @extend .input-radius;
      margin-bottom: 15px;
      outline: none;
    }

    button {
      @extend .input-radius;
      width: 50%;
      font-size: 1.3rem;
      font-weight: bold;
      margin-top: 25px;
      margin-bottom: 15px;
      border: none !important;
      outline: none !important;
      color: white;
      background-color: #0cdddd;
      align-self: flex-end;
      padding: 15px;

      &:hover,
      &:active,
      &:focus {
        background-color: rgb(12, 221, 221) !important;
      }
    }
  }

  .te-edit-user {
    width: 400px;
    z-index: 10;
    border-radius: 30px;
    padding: 15px 10px;
    max-width: 95%;
  }
  
  .te-edit-user, .te-quiz-page, .input-container {
    input, textarea {
      border-radius: 12px;
      border: solid 1px rgba(14, 15, 47, 0.25);
      padding: 8px 16px;
      margin-bottom: 20px;
      max-width: 100%;
  
      &[disabled] {
        background-color: #f2f2f2;
      }
    }
  
    label {
      color: #0e0f2f;
      font-size: 16px;
      font-weight: 600;
      padding-bottom: 5px;
      display: inline-block;
    }
  }

  .te-steps-section {
    h3 {
      margin-bottom: 20px;
    }

    .te-steps {
      counter-reset: te-step-counter;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      p {
        counter-increment: te-step-counter;
        font-size: 1.5rem;

        &::before {
          content: counter(te-step-counter);
          display: inline-block;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          color: #fff;
          background: #27ce80;
          margin-right: 10px;
          vertical-align: middle;
          text-align: center;
          padding: 8px;
        }
      }
    }
  }

  @media only screen and (max-width: 400px) {
    .te-intro-section {
      margin-top: 140px;
    }

    .te-create-game {
      margin-top: 20px;

      input {
        font-size: 1rem;
      }
    }

    .te-steps-section {
      h3 {
        margin-top: 35px;
      }
    }
  }
}

.h7t-loader {
  .loader,
  .loader:before,
  .loader:after {
    background: linear-gradient(#36fff4, #ffffff);
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 40px;
  }
  .loader {
    color: linear-gradient(#36fff4, #ffffff);
    text-indent: -9999em;
    margin: 10px auto;
    position: relative;
    font-size: 11px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }
  .loader:before,
  .loader:after {
    position: absolute;
    top: 0;
    content: "";
  }
  .loader:before {
    left: -1.5em;
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }
  .loader:after {
    left: 1.5em;
  }
  @-webkit-keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
  @keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
}

.spinner > div {
  background: linear-gradient(#36fff4, #ffffff);
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner {
  text-align: center;
  margin: 40px auto;
  width: 70px;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  100%,
  80% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%,
  100%,
  80% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.t-cursor-pointer {
  cursor: pointer;
}

.t-padding-0 {
  padding: 0px !important;
}

.t-padding-0-8 {
  padding: 0px 8px !important;
}

.t-margin-0 {
  margin: 0px !important;
}

.t-margin-8-16 {
  margin: 8px 16px;
}

.t-pos-relative {
  position: relative;
}

.t-flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.t-flex-row-justify-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.fullscreen {
  position: fixed;
  left: -225%;
  top: -0%;
  height: 120%;
  width: 230%;
  padding-top: 10px;
  border-radius: 30px;
  z-index: 100;
  video {
    width: 100%;
    border-radius: 20px;
  }
}

.t-media-share-container-sm {
  display: flex;
  height: 100%;
  width: 100%;
  video {
    width: 100%;
  }
}

.t-share-overlay {
  position: absolute;
  right: 0px;
  width: 30%;
  height: 30%;
  background-color: #21252963;
  z-index: 2;
}

.te-body {
  margin: auto;
  width: 88vw;

  .te-email-verify {
    padding: 6vmin 12vmin 6vmin 12vmin;
  }

  .te-create-account {
    padding: 0vmin 5vmin 0vmin 5vmin;
  }

  .te-hr-text {
    position: relative;
    text-align: center;
    margin-top: 0px;
    height: 1.5em;
    opacity: 1;
    &:before {
      content: "";
      background: black;
      position: absolute;
      left: 0;
      top: 60%;
      width: 100%;
      height: 1px;
    }
    &:after {
      content: attr(data-content);
      position: relative;
      display: inline-block;
      padding: 0 0.5em;
      background-color: #edf0ff;
    }
  }

  .te-upload-btn-wrapper {
    position: relative;
    background-color: white;
    text-align: center;
    border-bottom: 1px solid #ccc;
    width: 100%;
    height: 220px;
  }

  .te-upload-btn {
    position: absolute;
    color: #3c50e0 !important;
    background-color: rgba(255, 255, 255, 0.4) !important;
    border-color: #3c50e0 !important;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .te-upload-btn-wrapper input[type="file"] {
    position: absolute;
    opacity: 0;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 100%;
  }

  .te-create-account-img {
    background-image: url(https://cgs-cdn.s3.ap-south-1.amazonaws.com/team-engage/uploads/2021/09/14074943/create_account-inverted.png) !important;
    background-position: 90% 90% !important;
    background-repeat: no-repeat !important;
    background-size: 50vmin !important;
  }

  .te-verify-email-img {
    background-image: url(https://cgs-cdn.s3.ap-south-1.amazonaws.com/team-engage/uploads/2021/09/14045656/create_account.png) !important;
    background-position: 15% 50% !important;
    background-repeat: no-repeat !important;
    background-size: 50vmin !important;
    min-height: 500px;
  }

  .te-accept-invites-img {
    background-image: url(https://cgs-cdn.s3.ap-south-1.amazonaws.com/team-engage/uploads/2021/09/14045711/game_link-1.png) !important;
    background-position: 15% 50% !important;
    background-repeat: no-repeat !important;
    background-size: 50vmin !important;
    min-height: 500px;
  }

  .te-employer-signin-img {
    background-image: url(https://cgs-cdn.s3.ap-south-1.amazonaws.com/team-engage/uploads/2021/09/14045702/welcome_back-1.png) !important;
    background-position: 15% 50% !important;
    background-repeat: no-repeat !important;
    background-size: 60vmin !important;
  }

  .te-forgotpassword-img {
    background-image: url(https://cgs-cdn.s3.ap-south-1.amazonaws.com/team-engage/uploads/2021/09/14045717/reset_password-1.png) !important;
    background-position: 15% 50% !important;
    background-repeat: no-repeat !important;
    background-size: 55vmin !important;
    min-height: 500px;
  }

  @media only screen and (max-width: 993px) {
    .te-create-account-img,
    .te-verify-email-img,
    .te-accept-invites-img,
    .te-employer-signin-img,
    .te-forgotpassword-img {
      background-image: none !important;
    }
  }
  .te-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 2;
  }
  .te-modal-main {
    position: fixed;
    top: 40%;
    left: 50%;
    text-align: left;
    transform: translate(-50%, -50%);
    background-color: white !important;
    width: min-content;
    min-height: 20vmin;
    height: auto;
    overflow-y: auto;
    padding: 20px 50px 20px 50px;

    .te-title {
      width: max-content;
    }

    @media only screen and (max-width: 425px) {
      top: 50%;
      height: 90%;

      .te-title {
        width: min-content;
      }
    }
  }

  .te-modal-body {
    padding: 13px 0px 15px 0px;
    z-index: 3;
  }

  .te-display-block {
    display: block;
  }

  .te-display-none {
    display: none;
  }

  .te-stepper {
    position: relative;
    margin: auto;
    width: 70%;
    margin-top: 20px;
    @media only screen and (max-width: 900px) {
      width: 80%;
    }
    @media only screen and (max-width: 600px) {
      width: 90%;
    }
  }
  .te-step-bar {
    display: flex;
    flex-direction: row-reverse;
    // margin: 0 auto 0 auto;
    // margin-left: 100px;
  }

  .te-step {
    text-align: right;
    width: 100%;
    position: relative;
    z-index: -10;
  }

  .te-text-hide {
    color: white !important;
  }

  .te-bullet {
    border: 1.5px solid rgba(60, 80, 224, 0.2);
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    background-color: white;
    line-height: 10px;
    z-index: -1;
    left: -20%;
  }

  .te-bullet::before {
    content: "";
    position: absolute;
    right: 9px;
    bottom: 3px;
    height: 1px;
    width: 25vw;
    // width: 18vmax;
    background-color: rgb(176, 185, 255);
    z-index: 0;
  }

  .te-bullet.completed {
    background-color: #3c50e0;
  }

  .te-bullet.completed::before {
    content: "";
    position: absolute;
    right: 9px;
    bottom: 3px;
    height: 1px;
    width: 25vw;
    // width: 17.5vmax;
    background-color: #3c50e0;
    z-index: 0;
  }

  .te-margin-top-100 {
    margin-top: 100px;
  }

  .te-margin-top-40 {
    margin-top: 40px;
  }
}

.te-white-header {
  position: absolute;
  top: 0px;
  left: 0px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.te-color-header {
  background: url("https://cgs-cdn.s3.ap-south-1.amazonaws.com/team-engage/uploads/2021/08/10145659/home-background-tiny.png");
  background-size: 100%;
  background-color: #f2f6ff;
  background-repeat: no-repeat;
  @include te-bg-filter(30px);
}

.team-engage-header {
  position: sticky;
  width: 100%;

  .rectangle {
    margin: auto;
    @media only screen and (max-width: 600px) {
     width: 100%;
    }
  }
  .te-engage-header-custom-pad{
    padding: 0;
    @media only screen and (max-width: 600px) {
      padding: 8px 16px;
     }
  }

  .te-header-nav-wrap{
    padding: 8px 16px;
    width: 90%;
    @media only screen and (max-width: 600px) {
      padding: 0;
      width: 100%;
     }
  }

  .group-element {
    color: #3c50e0;
    list-style-type: none;
    margin: auto;
    width: 40vw;
  }
  .user-name {
    // font-family: Raleway, Arial, Helvetica, sans-serif;
    font-family: "Urbanist", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #3c50e0;
    list-style-type: none;
    text-decoration: none;
  }

  .logo1 {
    width: 100px !important;
    float: left;
    position: relative;
  }
  .register-btn {
    // font-family: Raleway, Arial, Helvetica, sans-serif;
    font-family: "Urbanist", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #ffffff;
    display: flex;

    justify-content: center;

    padding: 12px 20px 12px 20px;
    background: #3c50e0;
    border-radius: 70px;
    float: right;
    margin-top: 0px;
    cursor: pointer;
  }

  .te-profile-tag {
    padding: 8px 12px 8px 12px;
    background: #b4bcf5;
    border-radius: 50%;
    text-decoration: none;
    color: #3c50e0;
    width: 40px;
    height: 40px;
    margin: auto;
    cursor: pointer;

    @media only screen and (max-width: 600px) {
      width: 50px;
      height: 50px;
    }
  }

  .te-profile-pic-right {
    object-fit: cover;
    border-radius: 50%;
    width: 35px;
    height: 35px;
  }

  .te-setting-pop-up {
    position: absolute;
    text-align: center;
    padding: 10px;
    width: 120px;
    height: 90px;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 0px 15px 15px 15px;
    bottom: -70px;
    right: 80px;
    // @include te-bg-filter(30px);
  }

  .te-pop-up-text {
    color: #3c50e0;
    margin: 10px;
    text-decoration: none;
    list-style-type: none;
  }

  .te-burger-icon {
    margin: -10px -20px -20px 0px;
    width: 70px;
  }

  .te-burger-header {
    box-shadow: 0px 20px 30px -16px #bbb;
    border-radius: 0px 0px 20px 20px;
    min-height: 0px;
    margin: 0px 0px 5vh 0px;
    padding: 1px;
    color: black;
  }

  .te-burger-items {
    margin-block: 2vh;
  }

  .te-margin-top-8 {
    margin-top: 8px;
  }

  .te-margin-top-5 {
    margin-top: 5px;
  }
}

.team-engage-footer {
  .rectangle {
    width: 100%;
    background: #07003b;
    border-radius: 0px;
    padding: 0px 80px 0px 80px;
  }

  .rectangle-lite {
    width: 100%;
    background-color: #dbedfd;
    color: #07003b;
    padding: 0px 80px 0px 80px;

    @media only screen and (max-width: 600px) {
      padding: 0px 30px 0px 30px;
      line-height: 16px;
    }
  }

  .group-head {
    // font-family: Raleway, Arial, Helvetica, sans-serif;
    font-family: "Urbanist", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: 0.05em;
    margin-top: 25px;
    font-weight: bold;
  }

  .group-list {
    // font-family: Raleway, Arial, Helvetica, sans-serif;
    font-family: "Urbanist", sans-serif;
    font-size: 13px;
    margin: 15px 20px 0px 0px;

    @media only screen and (max-width: 600px) {
      font-size: 14px;
    }
  }

  .logo1 {
    width: 120px;
  }

  .space {
    padding-left: 6%;
  }

  .space2 {
    padding-left: 3%;
  }

  .btn {
    background: #07003b;
    color: #dde5ff;
    border-radius: 20px;
    // font-family: Raleway, Arial, Helvetica, sans-serif;
    font-family: "Urbanist", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0.05em;
  }

  .logo-prop {
    padding: 40px 0px 0px 0px;
    text-align: center;
  }
  .bottom-elements {
    height: 100px;
  }
}

.te-settings {
  margin: auto;
  width: 88vw;
  padding: 3vmin 0vmin 3vmin 0vmin;
  .drop-down {
    .navbar-expanded {
      top: 40px;
      left: 10px;
      z-index: 4;
      position: absolute;
      background: white;
      border-radius: 10px;
    }
  }
  .select_ul {
    top: 40px !important;
    z-index: 4 !important;
    left: inherit !important;
    right: 30px !important;
    padding: 8px;
  }
  .te-body-right {
    box-shadow: 0px 0px 35px 1px rgba(60, 80, 224, 0.16);
  }

  // .te-selected {
  //   text-decoration: underline;
  //   text-underline-position: under;
  // }

  .te-profile-content {
    margin: 2vmin 12vmin 2vmin 12vmin;
    @media screen and (max-width:"500px") {
     margin: unset;
    }
  }
  .te-profile-margin{
      margin: 15px;
      @media screen and (max-width:"500px") {
        margin: 15px 0;
       }
  }
  
  @media screen and (max-width:"500px") {
     .te-profile-center-mobile{
        display: flex;
        justify-content: center;
     }
     .te-margin-top-mobile{
       margin-top: 15px;
     }
     .te-profile-input-margin-top{
       margin-top: 5px;
     }
  }

  .te-settings-margin{
    margin-left: 16px;
  }

  .te-notification-channels{

   .te-channels-table-header{
     display: flex;
     border-bottom: 1px solid #ddd;
   } 
   .te-channels-dept-padding{
    padding-left: 12%;
   }
   .te-channels-webhook-padding{
     padding-left: 8%;
   }
   .te-channels-table-column{
     width: 100%;
     padding: 10px;
     display: flex;
     border-bottom: 1px solid #ddd;
   }
   .te-channels-logo{
     margin-right: 5px;
   }
   .te-channels-provider{
     width: 25%;
     margin: 0 5px;
   }
   .te-channels-department{
     width: 25%;
   }
   .te-channels-url{
     width: 40%;
     white-space: nowrap;
     overflow: scroll;
     cursor: pointer;
     margin: 0 5px;
   }
   .te-channels-btn-wrapper{
     width: 20%;
   }
   .te-channels-btn{
     cursor: pointer;
     margin: auto;
     font-size: 15px;
     font-weight: 600;
   }
   .te-channel-select-wrapper{
     display: flex;
     justify-content: space-between;
   }
   .te-channel-select-width{
     width: 200px;
   }
 
   @media screen and (max-width:"600px") {
      .te-channels-btn-wrapper{
        margin: 0 5px;
      }
      .te-channels-table-header{
        justify-content: space-between;
      }
      .te-channels-dept-padding{
        padding: unset;
      }
      .te-channels-webhook-padding{
        padding: unset;
      }
      .te-channels-margin-mob{
        margin: 0 5px;
      }
   }
  }

  .te-upload-btn-wrapper {
    position: relative;
    background-color: #f6f7fa;
    text-align: center;
    border-bottom: 1px solid #ccc;
    // width: 50%;
    height: 150px;
  }

  .te-upload-btn {
    position: absolute;
    color: #3c50e0 !important;
    background-color: white !important;
    border-color: #3c50e0 !important;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .te-upload-btn-wrapper input[type="file"] {
    position: absolute;
    opacity: 0;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 100%;
  }

  .te-offwhite {
    background-color: #f6f7fa !important;
  }

  .te-invite-btn {
    // float: right;
    outline: none;
  }

  .te-search-icon {
    background: #f6f7fa;
    padding: 7px 20px 0px 10px;
    border-bottom: 1px solid #ccc;
    border-radius: 0px 32px 32px 0px;
    color: #9d9a9a;
    font-size: 15px;
  }

  .te-input-wrap .te-search-icon :hover {
    color: #3c50e0;
    cursor: pointer;
  }

  .dashboard-options {
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .te-card {
    position: relative;
    background: #f1f2f9;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 1px 0px 10px 0px;
    width: 90%;
    margin-top: 40px;
    margin-left: 14px;
  }

  .te-crown-yellow {
    float: right;
    color: #d8e820;
  }
  
  .te-removal-popup {
    position: absolute;
    top: 30%;
    left: 5%;
    width: 90%;
    background: rgba(255, 255, 255, 1);
    color: #0b0b42;
    @include te-bg-filter(15px);
    border-radius: 10px;
    text-align: justify;
    border: 1px solid white;
    cursor: default;
  }

  .te-remove-opt {
    display: inline-block;
    width: 50%;
    text-align: center;
    margin: 10px 0px 10px 0px;
  }

  .te-input-icon {
    border-radius: 32px 0px 0px 32px;
    outline: none;
    padding-left: 25px;
    // width: 50%;
  }

  .te-width-reduce {
    width: 30%;
  }

  .te-input-popUp {
    margin: 0px 15px 0px 15px;
    padding-left: 25px;
    outline: none;
  }

  .te-input-wrap {
    display: flex;
  }

  .te-popUp {
    padding: 20px 30px 0px 0px;
    position: absolute;
    z-index: 3;
    left: 58%;
    width: 21%;
    background: #fff;
    border-radius: 20px 0px 20px 20px;

    @media only screen and (max-width: 600px) {
      left: 44%;
      width: 44%;
    }
  }

  .te-blue {
    background-color: #3c50e0;
  }

  .te-profile-pic {
    border: 1px solid #f6f7fa;
  }

  .te-profile-pic-name {
    height: 100px;
    width: 100px;
    margin: auto;
    padding: 8px 12px 8px 12px;
    background: #b4bcf5;
    border-radius: 50%;
    text-decoration: none;
    color: #3c50e0;
    padding-top: 35px;
  }

  .te-edit-wrapper {
    position: relative;
    width: 1px;
    height: 33px;
    border-radius: 50%;
    top: -33px;
    left: 33px;
    margin: auto;
    overflow: hidden;
    background-color: white;
    background-image: url(../images/editIcon.png) !important;
    background-size: 33px;
  }

  #profilepic-options input[type="file"] {
    position: absolute;
    opacity: 0;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 60%;
  }

  .te-flex-card {
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin: 4vmin;
  }

  .te-paycard {
    color: black;
    background-color: #f6f7fa;
    text-align: center;
    padding-bottom: 1vmin;
    margin-bottom: 10vmin;
  }

  .te-paycard-white {
    color: #3c50e0;
    background-color: white;
    border: #3c50e0 2px solid !important;
    padding-bottom: 1vmin;
  }

  .te-paycard-blue {
    color: white;
    background-color: #3c50e0;
    border: #3c50e0 2px solid !important;
    padding-bottom: 1vmin;
  }

  .te-paycard-details {
    padding: 2vmin;
    text-align: left !important;
  }

  .te-popular {
    background-color: #ff7a00;
    padding: 0px 20px 0px 20px;
    margin-bottom: 10px;
  }

  .te-popular-hide {
    background-color: white;
    color: white;
    padding: 0px 20px 0px 20px;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;  
    padding: 10px; 
    .profile-dropdown-mobile-wrapper{
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
    }  
    .te-settings-margin{
     margin-left: unset;
    }
    .te-import-btn{
     display: flex;
     justify-content: center;
    }
    .te-input-file-handler-wrapper{
      text-align: center;
    }
    .te-input-file-handler{
      width: 75%;
    }
  }

  .te-settings-calender-wrapper{
    @media only screen and (max-width: 600px) {
      .fc-toolbar{
      flex-direction: column;
      }
    }
  }
}

.te-change-pwd {
  .te-appear {
    position: relative;
    margin: auto;
    width: 45vmin;
    box-shadow: 0px 0px 35px 1px rgba(60, 80, 224, 0.16);
    // animation: appear 2s 1;
    // animation-delay: -0.5s;
    // animation-fill-mode: forwards;
  }

  @keyframes appear {
    from {
      top: -300px;
      box-shadow: none;
    }
    to {
      top: 0;
      box-shadow: 0px 0px 35px 1px rgba(60, 80, 224, 0.16);
    }
  }

  .te-disappear {
    position: relative;
    margin: auto;
    width: 45vmin;
    animation: disappear 3s 1;
    animation-delay: 2s;
    animation-fill-mode: forwards;
  }

  @keyframes disappear {
    from {
      top: 0;
      box-shadow: 0px 0px 35px 1px rgba(60, 80, 224, 0.16);
    }
    to {
      top: -300px;
      box-shadow: none;
    }
  }

  .te-pass-wrapper {
    position: relative;
    display: flex;
  }

  .te-eye-icon {
    position: absolute;
    top: 38%;
    right: 5%;
  }
}

.te-game-play {
  min-height: 400px;
  @include te-bg-filter(30px);

  .te-padding-top-12 {
    padding-top: 12px;
  }

  .te-btn-start {
    float: right;

    @media only screen and (max-width: 600px) {
      float: none;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }
  }

  .te-game-card {
    background: #1f4068;
    padding: 1px 0px 10px 0px;
    width: 80%;
    margin-top: 40px;
    margin-left: 14px;
  }

  .te-game-card-main {
    width: 96%;
    position: relative;
    left: 4%;
  }

  .te-back-arrow {
    font-size: 28px;
    line-height: 70px;
    color: #ffffff;
    cursor: pointer;
  }

  .te-back-arrow:hover {
    color: #3c50e0;
  }
}

.te-dashboard {
  margin: 0px 0px;
  padding: 12px 0px;
  @media only screen and (min-width: 601px) {
    padding: 12px 0px;
  }
  @media only screen and (min-width: 993px) {
    padding: 12px 24px;
  }
  background: linear-gradient(to top right, #212449, #242d6d);
  box-shadow: -5px 1px 0px 0px #4c3575;
  @include te-bg-filter(30px);


  &.te-event-list {
    background: none;
    box-shadow: none;

    @media only screen and (min-width: 601px) {
      margin: 25px 8px;
    }
  }

  .te-padding-top-12 {
    padding-top: 12px;
  }

  .te-btn-start {
    float: right;

    @media only screen and (max-width: 600px) {
      float: none;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }
  }

  .te-game-card {
    background-image: linear-gradient(to right, #181c43, #232964);
    border: solid 1px rgba(255, 255, 255, 0.05);
    box-shadow: -4px 4px 0px 0px #4c3575;
    //   margin-top: 40px;

    //   .te-admin-user {
    //     .te-button-blue,
    //     .te-button-transparent-dark {
    //       padding-left: 6%;
    //       padding-right: 6%;
    //     }
    //   }

    //   @media only screen and (max-width: 425px) {
    //     width: 100%;
    //     margin-left: 0px;
    //     margin-right: 0px;
    //   }

    //   @media only screen and (max-width: 1280px) {
    //     width: 100%;
    //     margin-left: auto;
    //     margin-right: auto;
    //   }
  }

  .te-game-card-main {
    width: 100%;
    position: relative;
  }

  .te-back-arrow {
    font-size: 28px;
    line-height: 70px;
    color: #ffffff;
    cursor: pointer;
  }

  .te-back-arrow:hover {
    color: #3c50e0;
  }
}

.te-profile-list-item {
  display: inline-flex;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  margin-left: -16px;
  font-weight: 600;
  font-size: 20px;
  width: 30px;
  height: 30px;
  align-self: center;
  justify-content: center;
  align-items: center;
  
  &:first-of-type {
    z-index: 5;
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
  &:not(:first-of-type) {
    margin-left: -10px;
    font-size: 16px;
  }
}

.te-game-counter {
  color: #fff;
  .te-counter-panel {
    background: #152c48;
    margin-top: 5px;
  }

  .te-list {
    list-style-type: none;
    display: flex;
  }

  .te-list-item-trivia {
    display: inline;
    padding: 4px 8px 4px 8px;
    margin-left: 0px;
    border-radius: 20px;
    margin-left: -32px;
    margin-right: 34px;
  }

  .te-profile-pic {
    width: 35px;
    height: 100%;
  }

  .te-profile-pic-small {
    width: 25px;
  }
}

.te-members {
  top: -13px;
  text-transform: capitalize;

  .te-member-list-wrap {
    max-height: 300px;
    height: auto;
    overflow-y: scroll;
    @media (min-width: 601px) {
      margin-right: -8px;
      &::-webkit-scrollbar {
        width: 8px;
      }
      
      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 10px 10px rgba(255, 255, 255, 0.04);
        border: solid 2px transparent;
        border-radius: 10px;
        visibility: hidden;
      }

      &:hover {
        &::-webkit-scrollbar-thumb {
          visibility: visible;
        }
      }
    }
  }
  
}

.te-event-btn {
  padding: 10px 30px !important;
  font-size: 14px;
}

.te-e-card-logo {
  display: inline;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  object-fit: contain;
  border-radius: 5px;
  background: white;
}

.te-create-game {
  margin: auto;
  padding-bottom: 80px;
  @media only screen and (min-width: 601px) {
    margin-left: 50px;
    margin-right: 50px;
  }
  
  label{
    color:white !important;
  }
  .prizeMsg{
    color: $textColor2 !important;
  }
  input, textarea {
    background-color: rgba(255, 255, 255, 0.04) !important;
    border: 1px $textColor2 solid;
    color:white;
    &::placeholder{
      color: $textColor2;
      opacity: 0.7;
    }
  }

  .te-rounds-wrapper {
    @media only screen and (max-width: 425px) {
      &.w3-margin-left {
        margin-left: 0px !important;
      }

      &.w3-margin-right {
        margin-right: 0px !important;
      }
    }
  }

  .te-card {
    text-align: center;
    background-repeat: no-repeat !important;
    background-position: center center;
    background-size: 100% 100% !important;
    @media only screen and (max-width: 425px) {
      &.w3-margin-left {
        margin-left: 0px !important;
      }

      &.w3-margin-right {
        margin-right: 0px !important;
      }
    }
  }

  .te-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 3;
  }

  .te-modal-main {
    z-index: 10;
    position: fixed;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);  
    background-image: linear-gradient(238deg, #373179, #2e296a, #1e1a4c);
    width: 75vW;
    max-width: 800px;
    max-height: 80vh;
    color:white !important;
    height: auto;
    overflow-y: auto;
    padding: 6vmin 3vmax !important;
    .te-text{
      color:$primaryColor3
    }
    button{
      font-weight: 600;
      width: 200px;
    }

    @media only screen and (max-width: 600px) {
      width: 92vW;
    }

    @media only screen and (max-width: 425px) {
      height: 90%;
    }
  }

  .te-modal-body {
    padding: 3vmin 0vmin 0vmin 0vmin;
    z-index: 1;
  }

  .te-display-block {
    display: block;
  }

  .te-display-none {
    display: none;
  }

  .te-accordion {
    width: 100%;
    border-bottom: 1px solid #3c50e0;
    line-height: 0.1em;
    margin: 5vmin 0vmin 5vmin 0vmin;
  }

  .te-accordion-text {
    background-color: #edf0ff;
    padding-right: 1vw;
  }

  .te-done {
    padding-left: 4vw;
  }

  .te-chevron {
    background-color: #edf0ff;
    margin-left: 100px;
  }

  .te-join-link {
    padding: 8px 52px 8px 16px;
  }

  .te-icon-wrapper {
    margin: auto;
    @media only screen and (min-width: 601px) {
      width: 70%;
    }
    position: relative;
    display: flex;
    width: 100%;
    .date-icon {
      background: url(../images/calendar-24.png) no-repeat right;
      background-size: 20px !important;
      background-position-x: calc(100% - 10px) !important;
      cursor: auto;
    }
  }

  .te-calender-icon {
    position: absolute;
    top: 38%;
    right: 5%;
  }

  .rdt {
    width: 100% !important;
  }

  .te-copy-icon {
    position: absolute;
    top: 30%;
    right: 15px;
  }

  .te-selected {
    // color: #3C50E0 !important;
    color: lightskyblue !important;
    // text-decoration: underline;
    // text-underline-position: under;
    // text-decoration-color: #3C50E0;
  }
}

.te-chip {
  display: inline-block;
  padding: 4px 24px 4px 24px;
  // font-size: 18px;
  line-height: 30px;
  background-color: rgba(255, 255, 255, 0.1);
}

.code-inputs {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
}
.code-input-individual {
  border-radius: 50%;
  height: 2.8rem;
  width: 2.8rem;
  border: none;
  outline: none;
  text-align: center;
}
.te-selected-games { 
  @media screen and (max-width:'600px') {
    padding: 0 !important;
    .game-type-title{
      margin-left: 0 !important;
    }
  }
  @include te-bg-filter(30px);
  .te-rounds-wrapper {
    display: flex;
    flex-wrap: wrap;
    @media only screen and (max-width: 425px) {
      &.w3-margin-left {
        margin-left: 0px !important;
      }

      &.w3-margin-right {
        margin-right: 0px !important;
      }
    }
  }
  .te-cards-container{
    @media only screen and (max-width: 600px) {
      justify-content: center;
    }
  }

  .te-card-container{
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .te-card-fungame {
    position: relative;
    z-index: 0;
    border-radius: 10px;
    width: 100%;
    border: solid 1px rgba(255, 255, 255, 0.2);
    background-color: rgba(255, 255, 255, 0.1);
    margin: 8px 0px;
    margin-bottom: 30px;
    button{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -10px;
      width: 150px;
    }
    @media only screen and (min-width: 601px) {
      width: 320px;
      margin: 8px;
      margin-bottom: 25px;
    }

    .thumbnail {
      position: relative;
      z-index: -1;
      img {
        // box-shadow: 0px 1px 10px 0px #0000008f;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        height: 150px;
        object-fit: cover;
        box-shadow: none;
      }
      .game-description-overlay {
        position: absolute;
        bottom: 0;
        background: linear-gradient(0deg, rgba(1, 6, 17, 0.7371) 35.33%, rgba(1, 6, 17, 0) 105%);
        height: 100%;
        width: 100%;
        .game-description {
          position: absolute;
        }
      }
    }
    @media only screen and (max-width: 425px) {
      &.w3-margin-left {
        margin-left: 0px !important;
      }

      &.w3-margin-right {
        margin-right: 0px !important;
      }
    }
  }


  .te-coinwon {
    position: absolute;
    left: 0px;
    top: 20px;
    z-index: 0;
    padding: 5px 10px 5px 28px;
    -webkit-clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 10% 50%, 0% 0%);
    clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 10% 50%, 0% 0%);
  }

  .te-btn-start {
    float: right;

    @media only screen and (max-width: 600px) {
      float: none;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }
  }

  .te-game-coin {
    width: 200px;
  }

  .te-summary {
    background: #51308a;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
  }

  .te-answer-icons {
    width: 26px;
  }

  .te-pop-up {
    position: absolute;
    //color: black;
    width: 220px;
    background: #f0f4fe;
    color: #0b0b42;
    @include te-bg-filter(15px);

    border-radius: 10px 10px 10px 0px;
    bottom: 20%;
    left: 45%;
    @media only screen and (max-width: 600px) {
      left: 30%;
    }
  }

  .te-pop-up-prize {
    top: 20px;
    right: 80px;
    position: absolute;
    width: 185px;
    background: #f0f4fe !important;
    color: #0b0b42;

    @include te-bg-filter(15px);
    border-radius: 10px 1px 10px 10px;
  }

  .te-pop-up-top3 {
    position: absolute;
    width: 180px;
    background: #f0f4fe;
    color: #0b0b42;
    @include te-bg-filter(15px);
    border-radius: 10px 10px 1px 10px;
    bottom: 35px;
    right: 10px;
  }

  .te-game-type-tag {
    padding:4px 8px;
    background: rgba(20, 25, 39, 0.75);
  }

  .top-teams {
    display: flex;
    justify-content: center;
    width: 90%;
    color: $textColor2;
    border-radius: 16px;
    padding: 8px;
    margin: auto;
    height: 75px;
    padding-bottom: 80px;
    div:nth-child(2) .te-pop-up-prize {
      width: 150px;
      border-radius: 10px 10px 10px 0px;
      width: 150px;
      left: 40px;
      top: -140px;
    }
    div:nth-child(3) .te-pop-up-prize {
      width: 150px;
      border-radius: 10px 10px 0px 10px;
      width: 150px;
      left: unset;
      right: 50%;
      top: -140px;
    }
    .top-teams-team:nth-child(1) .popup-name,
    .top-teams-team:nth-child(2) .popup-name {
      position: absolute;
      background: white;
      color: black;
      bottom: -10px;
      left: 40px;
      border-radius: 10px 10px 10px 0px;
    }
    .top-teams-team:nth-child(3) .popup-name {
      position: absolute;
      background: white;
      color: black;
      bottom: -10px;
      right: 40px;
      border-radius: 10px 10px 0px 10px;
    }
    .top-teams-team {
      width: 33%;
      text-align: center;
      position: relative;
      .top-teams-profile-img {
        border-radius: 50%;
        border: solid #111927 2px;
        color: black;
        width: 40px;
        height: 40px;
        text-align: center;
        padding-top: 8px;
        background-color: rgba(255, 255, 255, 0.74);
        margin: auto;
        object-fit: cover;
      }
      .profile-pic {
        border: none;
        padding-top: 0;
        object-fit: cover;
      }
      .no-player {
        border: dashed white 1px;
        background-color: transparent;
      }
    }
  }
}

.popup-img {
  padding: 5px 2px;
  background: #b4bcf5;
  border-radius: 50%;
  text-decoration: none;
  color: #3c50e0;
}

.te-header-app {
  // background: rgba(7, 0, 59, 0.5);
  background: #000;
  position: sticky;
  width: 100vw;
  top: 0px;
  z-index: 10;

  .te-header-container {
    padding: 10px 15px;
    @media only screen and (min-width: 601px) {
      padding: 10px 25px;
    }

    @media only screen and (min-width:993px) {
      padding: 10px 50px;
    }

    .te-corporate-group {
      color: rgba(255, 255, 255, 0.7);
      padding: 6px;
      padding-left: 15px;
      margin-left: 15px;
      border-left: 1px solid #4a4c5c;
    }

    .te-header-coins {
      margin-left: 20px;
      color: #ffc55b;
      font-weight: 600;

      .w3-button {
        &:hover {
          color: #ffc55b !important;
        }
      }
      
      span.coin-text {
        padding-left: 5px;
        sub {
          font-size: 16px;
        }
      }
    }

   

    .te-notification-button {
      position: relative;
      cursor: pointer;
      margin: 8px 4px 8px 8px;
      @media only screen and (min-width: 601px) {
        margin: 8px 30px 8px 14px;
      }
      
      span.te-notification-active {
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: #f14f85;
        right: 2px;
        top: 3px;
        border: solid 1px #140c35;
      }
    }
  }

  .te-sidebar {
    top: 0px;
    width: 100%;
    background: transparent;
    transition: transform 300ms ease-in;
    @media only screen and (min-width: 601px) {
      transition: transform 150ms ease-in;
    }

    &.is-hidden {
      transform: translateX(-100vW);
      transition: transform 300ms ease-out;
    }

    .te-sidebar-content {
      width: 80vW;
      background: white;
      height: 100%;
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      background-color: rgba(0, 0, 0, 0.7);
      @media only screen and (min-width: 601px) {
        width: 40vW;
      }
    }

    .te-sidebar-header {
      background: rgba(255, 255, 255, 0.05);
      padding: 18px 24px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .te-user-container {
        margin-left: 15px;
        color: rgba(255, 255, 255, 0.7);

        .te-name {
          margin: 0px;
          color: #fff;
          font-size: 16px;
          word-break: break-word;
        }

        .te-email {
          margin: 0px;
          font-size: 12px;
          line-height: 1;
          word-break: break-word;
        }
      }
    }

    .te-sidebar-menu-container {
      padding: 16px 16px;
      color: rgba(255, 255, 255, 0.7);
      display: flex;
      flex-direction: column;

      a {
        text-decoration: none;
        font-size: 16px;
        padding: 12px 16px !important;
      }
    }
  }

  .te-rectangle {
    width: 92vw;
    margin: auto;

    @media only screen and (max-width: 1000px) {
      width: 100vw;
    }
  }

  .te-group-element-left {
    display: flex;
    align-items: center;
  }

  .te-group-element {
    a {
      text-decoration: none;

      &:hover {
        color: #fff;
        font-weight: 500;
      }
    }
    // @media only screen and (max-width: 992px) {
    //   background-color: #171239;
    //   left: 0;
    //   top: 60px;
    //   width: 100vw;
    //   color: #120c57;
    //   padding: 0px 16px;
    //   display: flex;
    //   flex-direction: column;
    //   position: absolute;
    //   .te-selected {
    //     background: #384250;
    //   }
    //   a {
    //     text-decoration: none;
    //     text-align: center;
    //   }
    // }
    @media only screen and (max-width: 600px) {
      top: 100px;
    }
  }

  .te-group-element-right {
    display: flex;
    color: rgba(255, 255, 255, 0.7);
    align-items: center;
    justify-content: flex-end;
    .profile-section {
      padding-left: 10px;
      height: 3rem;
      transform: translateY(10px);
    }
    @media only screen and (max-width: 600px) {
      align-items: flex-end;
    }
  }

  .te-image-wrap {
    margin-top: -8px;
    // margin-right: -150px;
  }

  .te-profile-tag {
    padding: 8px 12px 8px 12px;
    background: #b4bcf5;
    border-radius: 50%;
    text-decoration: none;
    color: #3c50e0;
  }

  .te-profile-pic {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
  }

  .te-alert-banner {
    position: absolute;
    background: #e0d932;
    color: black;
    text-align: center;
    width: 100%;
    height: 28px;
    padding: 4px 0px;
    animation: mymove 2s linear;
    z-index: -1;
    //transition-timing-function: ease-in;
  }

  @keyframes mymove {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.te-game-l-board {
  .te-score-card-container {
    :first-child {
      margin-bottom: 15px;
    }

    @media only screen and (min-width: 601px) {
      display: flex;
      :first-child {
        margin-bottom: 0px;
      }
    }

    
  }
  .te-score-card {
    img {
      object-fit: contain !important;
    }
    border: solid 1px rgba(255, 255, 255, 0.16);
    background-color: rgba(255, 255, 255, 0.04);
    min-height: 50vh;
    @media screen and (max-width: 600px) {
      background-color: rgba(39, 44, 96, 0.5);
    }
  }

  .te-barcontainer {
    position: relative;
    width: 80%;
    margin: 0 auto;
    min-height: 150px;
    text-align: left;
    margin-left: 30%;

    @media only screen and (max-width: 600px) {
      margin-left: 25%;
      min-height: 150px;
      width: 100%;
    }
  }

  .te-bar {
    border-radius: 8px;
    width: 90%;
    text-align: center;
    padding-top: 6px;
    cursor: pointer;
  }

  .te-text-dark {
    color: #000000;
  }

  .te-bar-2 {
    position: relative;
    background: #abbfff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    height: 95px;
    margin-top: 45px;
    opacity: 0;
    animation: fillvalue-bar1 0.5s 0.3s forwards ease-out;
    transform-origin: 0% 100%;
  }

  .te-bar-1 {
    position: relative;
    background: #3c50e0;
    box-shadow: 0px 0px 25px rgba(60, 80, 224, 0.18);
    height: 140px;
    opacity: 0;
    animation: fillvalue-bar1 0.5s 0.6s forwards ease-out;
    transform-origin: 0% 100%;
  }

  .te-bar-3 {
    position: relative;
    background: #fbfbfb;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
    height: 72px;
    margin-top: 67px;
    opacity: 0;
    animation: fillvalue-bar1 0.5s forwards ease-out;
    transform-origin: 0% 100%;
    z-index: 2;
  }

  .winners-trophy {
    animation: winners-trophy 0.5s infinite ease-in-out alternate;
  }


  .te-pop-up {
    background: #1f4068;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.25);
    position: absolute;
    border-radius: 10px 10px 10px 4px;
    z-index: 0;
  }

  .te-pop-up1 {
    bottom: 60%;
    left: 60%;
    @media only screen and (max-width: 600px) {
      bottom: 60%;
      left: 1%;
    }
  }

  .te-pop-up2 {
    bottom: 60%;
    left: 50%;
    @media only screen and (max-width: 600px) {
      bottom: 60%;
      left: 1%;
    }
  }

  .te-pop-up3 {
    bottom: 50%;
    left: 30%;
    z-index: 5;
    @media only screen and (max-width: 600px) {
      bottom: 50%;
      left: 1%;
    }
  }

  .te-member-name {
    width: 150px;
    text-align: left;
    margin-top: 4px;
  }
}

.te-question {
  border: solid 1px rgba(255, 255, 255, 0.16);
  background-color: rgba(255, 255, 255, 0.04);
  @media only screen and (max-width: 600px) {
    background-color: rgba(39, 44, 96, 0.5);
    border-right: 1px solid #4c5180;
  }
  .te-question-card {
    text-align: center;
    min-height: 50vh;
    color: white;
    position: relative;
    padding-bottom: 80px;
    margin-bottom: 20px !important;
  }

  .back-arrow-icon {
    top: 18px;
    left: 15px;
    position: relative;
    cursor: pointer;
  }

  .question-parts {
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .te-question-progress {
    justify-content: center;
    align-items: center;
    padding: 15px;
    margin-top: 15px;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    border-radius: 10px;
  }

  .gallery_img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 300px;
    object-fit: cover;
  }

  .responsive {
    width: fit-content;
    display: block;
    margin-left: auto;
    margin-bottom: 20px;
    margin-right: auto;
  }
}

.te-answer {
  margin: auto;
  width: 60vw;

  .te-game-input {
    width: 400px;
    margin: auto;
    padding-left: 2vmin;
    padding-right: 2vmin;
    background: none !important;
    border: 1px #9cc2ff solid;
    color: white;
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #9cc2ff;
      opacity: 1; /* Firefox */
    }
    
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #9cc2ff;
    }
    
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: #9cc2ff;
    }

    @media only screen and (max-width: 800px) {
      width: 100%;
    }
  }
}

.te-game-countdown {
  height: 80vh;
  text-align: center;
  background: rgba(22, 36, 71, 0.4);

  @include te-bg-filter(10px);
  .te-rhombus {
    width: 240px;
    height: 240px;
    background-color: #00a94b;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    border-radius: 30px;
  }

  .te-circle {
    width: 240px;
    height: 240px;
    // padding-left: 15px;
    background-color: #fbbc04;
    clip-path: circle(50% at 50% 50%);
  }

  .te-square {
    width: 240px;
    height: 240px;
    // padding-left: 20px;
    background-color: #ea4335;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    border-radius: 15px;
  }
}

.te-frame-ques {
  max-width: 60vmax;
  margin: auto;

  .te-frame-qn-section-head{
    background: rgba(255, 255, 255, 0.1);
    border-radius: 25px;
    display: flex;
    align-items: center;
    cursor: move;

    @media only screen and (max-width: 600px) {
      width: 60%;
      margin: auto;
    }
  }
  .te-frame-qn-input{
    border-bottom: unset;
    background: unset;
    color: #ffffff;
    text-align: right;
  }
}

.te-chatbox {
  position: fixed;
  background: #{$primaryBgColor};
  width: 80px;
  height: 350px;
  right: 0;
  z-index: 6;
  height: 100%;

  @include te-bg-filter(3px);
  .icon-list {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    height: 100%;
    border-radius: 0px;
    .icons {
      height: 30px;
      cursor: pointer;
    }
  }
  .expand-icon {
    display: flex;
    width: fit-content;
    position: absolute;
    top: 28px;
    left: -15px;
    z-index: 100;

    @media only screen and (max-width: 600px) {
      display: none;
    }

    &.expand {
      transform: rotate(180deg);
    }

    img {
      height: 30px;
      cursor: pointer;
    }
  }

  &.te-chatbox-collapsed {
    position: relative;
    .te-event-logo {
      width: 35px !important;
      height: 35px !important;
    }

    .te-event-desc, .te-chat-top {
      display: none;
    }

    .chat-content {
      height: auto;
      padding-bottom: 0px;
    }
  }
}

.chat-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
}

.chat-content-block {
  padding: 10px 0px;
  position: relative;
  height: calc(100vH - #{$headerHeight} - 66px - 61px);

  @media screen and (max-width: 600px) {
    height: calc(100vH - #{$headerHeight} - 49px - 61px);
  }
}

.chat-icons-container {
  width: 100%;
}

.te-chatbox-expanded {
  top: 0px;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 0px !important;
  animation: animatetop 0.4s;

  .chat-icons-container {
    position: absolute;

    &.te-chat-tab-options {
      display: none;
    }
  }

  .icon-list {
    padding-top: 0px;
    padding-bottom: 0px;
    bottom: 0%;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    height: 10%;
    background-color: $primaryBgColor;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    border-bottom: none;
    img {
      cursor: pointer;
    }
  }
  .chatbox-content {
    height: calc(100% - 52px);
    color: white;
    overflow: hidden;
    padding: 0px 20px;
    h4 {
      font-weight: 500;
      margin: 0px;
      padding: 10px 0px;
      font-size: 18px;
    }

    .te-message-send-block {
      position: absolute;
      bottom: -61px;
      left: 0;
      height: 61px;
      width: 100%;
      padding: 15px 20px;
      border-top: 1px solid rgba(255, 255, 255, 0.15);
    }
  }

  .chat-icons-container {
    bottom: 0px;
  }
}

.chat-component {
  height: calc(100% - 50px);
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 50px);
  justify-content: space-between;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
  .chat-messages {
    display: flex;
    flex-direction: column;
    background-color: none;
    overflow-y: scroll;
    height: 100%;
    padding-bottom: 20px;

    @media (min-width: 601px) {
      margin-right: -8px;
      &::-webkit-scrollbar {
        width: 8px;
      }
      
      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 10px 10px rgba(255, 255, 255, 0.04);
        border: solid 2px transparent;
        border-radius: 10px;
        visibility: hidden;
      }

      &:hover {
        &::-webkit-scrollbar-thumb {
          visibility: visible;
        }
      }
    }

    .message {
      padding: 0px 10px 10px 10px;
      margin: 10px 10px 0px 0px;
      background-color: rgba(255, 255, 255, 0.07);
      border: solid 1px rgba(255, 255, 255, 0.25);
      border-radius: 0px 10px 10px 10px;
      width: fit-content;
      font-size: 16px;

      .te-message-user {
        font-size: 16px;
        font-weight: bold;
        margin-top: 8px;

        &.me {
          margin-top: 0px;
          text-align: right;
          display: none;
        }
      }
    }

    .messages-by-me {
      background-color: #8626d3;
      border: none;
      border-radius: 10px 0px 10px 10px;
      padding: 10px 15px 10px 15px;
      align-self: flex-end;
      margin: 10px 0px 0px 10px;
    }

    .messages-by-others {
      background-color: rgba(255, 255, 255, 0.07);
      border: 1px solid rgba(255, 255, 255, 0.15);
    }

    .messages-by-gamebot {
      background-color: rgba(255, 255, 255, 0.07);
      color: rgba(255, 255, 255, 0.5);
      border-radius: 29px;
      border: none;
      padding: 5px 15px;
      font-size: 12px;
    }

    .te-message-date {
      display: flex;
      justify-content: center;
      margin: 20px 0px 10px;
    }
  }
}

.send-message {
  height: 30px;
  display: flex;
  input {
    width: 100%;
    margin: 0px 10px 0px 0px;
    background-color: transparent;
    outline: none;
    border: 0;
    color: white;
  }
}

.videochat-component {
  display: flex;
  flex-direction: column;
  background-color: none;
  overflow-y: scroll;
  max-height: calc(100% - 50px);
  padding: 5px 0px 10px 0px;
  margin-left: -5px;
  margin-right: -5px;

  @media (min-width: 601px) {
    margin-right: -13px;

    &::-webkit-scrollbar {
      width: 8px;
    }
    
    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 10px 10px rgba(255, 255, 255, 0.04);
      border: solid 2px transparent;
      border-radius: 10px;
      visibility: hidden;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }
  }

  .te-chat-video {
    width: 100%;
    height: 100px;
    background: #000;
    object-fit: cover;
  }
}

.te-vcard-grouped {
  background: #d6449c;
  border-radius: 5px;
}

.t-member-list {
  padding: 5px;

  .te-vchat-card {
    border: 1px solid rgba(255, 255, 255, 0.16);
    border-radius: 6.5px;
    overflow: hidden;
    position: relative;
    min-height: 100px;
    background-color: rgba(255, 255, 255, 0.04);
  }

  .te-video-default-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 18px;
  }

  .te-video-default-view {
    background-color: rgba(255, 255, 255, 0.7);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4b2a86;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .te-video-action-container {
    display: flex;
    position: absolute;
    bottom: 8px;
    justify-content: center;
    width: 100%;

    button {
      margin: 0px 5px;
    }
  }
}

.te-celebrate {
  background: transparent;
  text-align: center;

  @include te-bg-filter(30px);

  padding: 15px 0px;

  @media only screen and (min-width: 601px) {
    padding: 15px 25px;
  }
}
.te-suggested-round {
  width: 25vw;
  @media only screen and (max-width: 600px) {
    padding: 0 30px;
  }
}
.trivia-complete-round-wrapper{
  display: grid;
  height: fit-content;
}
.trivia-complete-round-top-section{
  border: solid 1px rgba(255, 255, 255, 0.16);
  background-color : rgba(255, 255, 255, 0.04);
  border-radius: 10px;
}
.trivia-complete-round-bottom-section{
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 993px) {
   display: unset;
   }
}
.trivia-complete-round-border-bottom{
 border-bottom: solid 1px #484d77;
}
.trivia-complete-round-sub-title{
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  opacity: 0.5;
}
.trivia-complete-round-total-ques{
  border-right: solid 1px rgba(255, 255, 255, 0.16);
  @media only screen and (max-width: 993px) {
    border-bottom: solid 1px rgba(255, 255, 255, 0.16);
   }
}
.trivia-complete-round-count-title{
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  opacity: 0.5;
}
.trivia-section-align{
  display: flex;
  justify-content: space-evenly;
}
.trivia-complete-section-btn{
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: -20px;
}
.trivia-complete-round-align-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.te-suggest-round-wrapper{
  display: grid;
  padding-left: 20px;
  @media only screen and (max-width: 993px) {
   padding-left: unset;
  }

  .te-suggest-round-card{
    border : solid 1px rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    position: relative;
    width: 80%;
    @media only screen and (max-width: 1200px) {
      width: 100%;
     }
  }
  .te-suggest-round-desc{
    padding: 20px 15px 40px 15px;
    color: #9cc2ff;
    text-align: left;
    word-break: break-all;
  }
  .te-suggest-game-title{
    color: white;
    padding-bottom: 5px;
  }
  .te-suggest-round-img{
    border-radius: 10px;
    height: 150px;
    object-fit: cover;
  }
}

.te-settings-dropdown-wrap{
  position: absolute;
  top: 0;
  margin-left: 5px;
}

.te-header-dropdown-wrapper{
  background: transparent;
  position: absolute;
  margin: 10px 0
}

.te-header-dropdown{
  position: absolute;
  min-width: 160px;
}

.te-dropdown{
  border: solid 1px transparent !important;
  background-color: #2b1e42;
  color: #fff;
  border-radius: 10px;
  right: -24px;
  top: 50px;

  a {
    background-color: unset ;
  }
  a:hover{
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: #d6449c !important;
  }

  .te-dropdown-hr{
    margin: 5px 0;
    border-top: 1px ridge rgba(255, 255, 255, 0.24);
  }
}

.te-dropdown::before{
  content: '';
  display: block;
  position: absolute;
  right: 32px;
  top: -21px;
  bottom: 100%;
  width: 0;
  height: 0;
  border-bottom: 10px solid #2b1e42;
  border-top: 10px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}


.te-dropdown-event-card{
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: solid 1px rgba(255, 255, 255, 0.24) !important;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 25px;
  right: -24px;
  top: 50px;

  .te-dropdown-hr{
    margin: 5px 0;
    border-top: 1px ridge rgba(255, 255, 255, 0.24);
  }
}
.te-dropdown-event-card::before{
  position: absolute;
  top: 25px;
  left: -9px;
  content: '';
  width: 0;
  height: 0;
  border-right: solid 8px rgba(255, 255, 255, 0.5);
  border-bottom: solid 8px transparent;
  border-top: solid 8px transparent;
}

.te-gallery-dropdown-event-card {
  position: absolute;
  right: -165px;
  top: -18px;
  width: 150px;
  z-index: 100;
  padding: 0px 20px;
}

.more-icon {
  cursor: pointer;
  position: relative;
  padding-left: 25px;
}


.te-gallery {
  .te-gallery-body {
    width: 100%;
    min-height: 70vH;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.04);
    border-radius: 10px;
    border: solid 1px rgba(255, 255, 255, 0.16);
    padding: 20px 5px;
    
    @media only screen and (min-width: 601px) {
      padding: 20px 20px;
    }
  }

  .te-gallery-upload-wrapper{
    margin-right: 20px;
    @media only screen and (max-width: 500px) {
      margin-right: unset;
     }
  }

  .te-gallery-upload-btn{
    cursor: pointer;
    padding: 9px 30px;
    margin-bottom: 15px;
    &:hover {
      background: rgba(255, 255, 255, 0.15);
    }
  }

  .te-gallery-upload-confirm-btn{
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 500px) {
      margin-top: 15px;
     }
  }

  .te-upload-btn-section-wrap{
    display: flex;
    align-items: baseline;
    margin-right: 20px;
    @media only screen and (max-width: 500px) {
     display: block;
     margin-right: 0;
    }
  }
  .te-moment-gallery-wrap-section{
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    margin-bottom: 5px;
    width: 100%;
  }
  .te-close-applied-filter{
    cursor: pointer;
    padding-left: 3px;
    font-weight: 500;
  }
  .te-applied-filter{
    padding: 2px 10px;
    margin: 0px 10px;
    border: solid 1px rgba(255, 255, 255, 0.16);
    display: flex;
    align-items: center;
    border-radius: 10px;
    margin-top: 10px;
  }
  .te-applied-filter-wrapper{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px 0;
    margin-top: -20px
  }

  .te-inline{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .te-gallery-filter-wrap {
    cursor: pointer;
    padding-right: 20px;
    position: relative;
  }
  .te-gallery-bold{
    font-weight: bold;
  }

  .te-gallery-border-bottom {
    border-bottom: solid 1px rgba(255, 255, 255, 0.24);
  }

  .te-gallery-align-center{
    display: flex;
    align-items: center;
  }
  .te-gallery-btn{
    padding: 8px 30px;
    cursor: pointer;
  }
  .te-gallery-filter-top-section{
    display: flex;
    width: 300px;
    height: 150px;
  }
  .te-gallery-filter-bottom-section{
    justify-content: space-between;
    padding: 10px 5px;
  }
  .te-gallery-dropdown{
    position: absolute;
    right: 10px;
    top: 38px;
    z-index: 10;
  }
  .te-filter-option-wrap{
    border-right: solid 1px rgba(255, 255, 255, 0.24);
  }
  .te-gallery-filter-option{
    padding: 15px 25px;
    cursor: pointer;
    font-size: 12px;
  }
  .te-gallery-checkbox-options-wrap{
    padding: 15px 10px;
  }
  .te-gallery-radio-options{
    text-align: left;
    padding-bottom: 5px;
  }
  .te-gallery-pad-10{
    padding: 10px;
  }
  .te-gallery-event-card{
    padding: 8px;
    border-radius: 25px;
    height: 200px;
    position: relative;
  }
  .te-gallery-event-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  .te-gallery-enddate{
   caret-color: transparent;
   opacity: 0.5;
  }
  .te-gallery-datepicker{
    width: 100%;
    border: solid 1px #fff;
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    font-family: "Raleway", "sans-serif";
    color: #fff;
    font-size: 8px;
    padding: 6px 4px;
  }

  .te-datepicker-wrap{
    padding: 4px;
  }
  .te-calender-icon{
    position: absolute;
    right: 8px;
    top: 5px;
    width: 35%;
  }
  .te-checkbox-label{
    padding-left: 22px;
    font-size: 12px;
  }
 
  .te-checkbox-container {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .te-checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .te-checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 15px;
    width: 15px;
    border-radius: 2px;
    border: solid 1px #fff;
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
  
  .te-checkbox-container input:checked ~ .te-checkmark {
    background-color: #d6449c;
    border: none;
  }
  
  .te-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .te-checkbox-container input:checked ~ .te-checkmark:after {
    display: block;
  }
  
  .te-checkbox-container .te-checkmark:after {
    left: 4px;
    top: 2px;
    width: 6px;
    height: 9px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  
  .rdtPicker{
    background: #21265E;
    border-radius: 8px;
    border: unset;
    right: -40px;
  }
  .rdtPicker td.rdtDay:hover, .rdtPicker td.rdtHour:hover, .rdtPicker td.rdtMinute:hover, .rdtPicker td.rdtSecond:hover, .rdtPicker .rdtTimeToggle:hover{
    background: unset;
  }
  .rdtPicker thead tr:first-of-type th:hover {
    background: unset;
}

  .te-grid-container {
    padding: 1em;
    column-count: 4;
  }

  .te-grid-item {
    box-sizing: border-box;
    border-color: hsla(0, 69%, 6%, 0.5);
    border-radius: 10px;
    margin: 10px;
    object-fit: contain;
  }

  .te-image-item {
    width: 100%;
    margin-bottom: 1em;
    border: 3px ridge #29ccff;
  }

  .te-upload-btn-wrapper {
    position: relative;
  }

  .te-upload-btn-wrapper input[type="file"] {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 100%;
  }
  .te-gallery-success-msg{
    @media only screen and (max-width: 500px) {
      margin-top: 20px;
     }
  }

  .te-chip {
    display: inline-block;
    padding: 4px 24px 4px 24px !important;
    font-size: 16px;
    line-height: 28px;
    position: relative;
    margin-right: 20px;
    border-radius: 32px;
    background-color: rgba(66, 66, 66, 0.4);
    @include te-bg-filter(30px);

    @media only screen and (max-width: 500px) {
     margin-right: unset;
    }
  }

  .te-button-moment-gallery {
    border: none;
    padding: 0;
    background: none;
    color: #d6449c;
    font-size: 14px;
    cursor: pointer;
  }


  
}

.captured-by-title {
  font-family: Raleway;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.overlay-preview-gallery {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 6;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  cursor: pointer;

  .time-stamp {
    color: #9cc2ff;
    font-size: 14px;
  }

  .slick-arrow {
    &::before {
      content: '';
    }
    .te-gallery-arrow {
      background: url(../images/icons/arrow-left-circle.png);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 30px;
      height: 30px;
      display: block;

      &.prev {
        margin-left: -20px;

        @media only screen and (min-width: 993px) {
          margin-left: -40px;
        }
      }

      &.next {
        transform: rotate(180deg);
        margin-left: 10px;

        @media only screen and (min-width: 993px) {
          margin-left: 30px;
        }
      }
    }

    &.slick-disabled {
      .te-gallery-arrow {
        opacity: 0.2;
        cursor: not-allowed;
      }
    }
  }
}

.preview-image {
  width: 70%;
  @media only screen and (min-width: 993px) {
    width: 75%;
  }
  max-height: 60vH !important;
  max-width: 100%;
  position: absolute;
  top: 38vH;
  left: 50%;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
  }
  .slick-slide {
    position: relative;  
    z-index: 1; 
  }
  .slick-slide.slick-current {
    z-index: 10;
  }
}

.centred-image {
  height: 300px !important;
  @media only screen and (min-width: 601px) {
    height: 65vH !important;
  }
  margin: 5px;
  display: flex !important;
  justify-content: center;
  align-content: center;
}

.te-videocollage {
  .te-vc-area {
    width: 54vw;
    height: 30vw;
    background-color: #000000;
    margin: auto;
    border-radius: 20px;
    color: #444;
  }

  .te-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);
    z-index: 10;
  }

  .te-modal-main {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent !important;
    width: auto;
    min-height: 20vw;
    height: auto;
    overflow-y: auto;
    padding: 10px !important;

    @media only screen and (max-width: 800px) {
      width: 80%;
    }

    @media only screen and (max-width: 425px) {
      min-height: 40vw;
      width: 95%;
    }

    .te-alert-container {
      width: 100%;
      margin: 0 auto;
      
      @media only screen and (min-width: 601px) {
        width: 75%;
      }

      @media only screen and (min-width: 993px) {
        width: 60%;
      }
    }
  }

  .te-modal-body {
    z-index: 1;

    #captured_img {
      img {
        border-radius: 15px;
      }
    }
  }
}

.te-leaderboards {
  // font-family: Raleway, Arial, Helvetica, sans-serif;
    font-family: "Urbanist", sans-serif;
  .te-lastgame {
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
    .te-lastgame-bar {
      display: flex;
      position: relative;
      width: 200px;
      height: 250px;
      margin: 50px;
      .bar3 {
        // background: linear-gradient(to top, #839ED0 50%, rgba(255, 255, 255, 0) 50%);
        background: #b6bef5;
        position: absolute;
        width: 43px;
        // background-size: 100% 200%;
        transition: all 0.5s ease-out;
        clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);
        animation: fillvalue-bar3 0.6s forwards;
        height: 100px;
        top: 125px;
        left: -170px;
        border-radius: 10px 10px 20px 20px;
        transform-origin: 70% 90%;
        opacity: 0;
      }
      .bar3-text {
        position: absolute;
        width: max-content;
        transform: rotate(-15deg);
        top: 95px;
        left: -205px;
        animation: fadeIn 0.6s 0.6s forwards;
        opacity: 0;
      }
      .bar1 {
        // background: linear-gradient(to top, #3C50E0 50%, rgba(255, 255, 255, 0) 50%);
        background: #3c50e0;
        position: absolute;
        width: 65px;
        top: 40px;
        left: -138px;
        // background-size: 100% 200%;
        transition: all 0.5s ease-out;
        clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);
        animation: fillvalue-bar1 0.6s 0.9s forwards ease-out;
        height: 180px;
        border-radius: 10px 10px 20px 20px;
        transform-origin: 50% 100%;
        opacity: 0;
      }
      .bar1-text {
        position: absolute;
        left: -130px;
        top: 10px;
        width: max-content;
        animation: fadeIn 0.6s 1.5s forwards ease-out;
        opacity: 0;
      }
      .bar2 {
        // background: linear-gradient(to top, #839ED0 50%, rgba(255, 255, 255, 0) 50%);
        background: rgb(131, 158, 208);
        position: absolute;
        width: 45px;
        // background-size: 100% 200%;
        transition: all 0.5s ease-out;
        opacity: 0;
        clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);
        animation: fillvalue-bar2 0.6s 0.3s forwards ease-out;
        height: 140px;
        transform: rotate(15deg);
        top: 85px;
        left: -90px;
        border-radius: 10px 10px 20px 20px;
        transform-origin: 30% 110%;
      }
      .bar2-text {
        position: absolute;
        top: 60px;
        width: max-content;
        left: -40px;
        transform: rotate(15deg);
        animation: fadeIn 0.6s 0.9s forwards ease-out;
        opacity: 0;
      }
      .bar-shadow {
        position: absolute;
        width: 135px;
        background: black;

        @include te-bg-filter(15px);
        transform: scale(1, 0.2);

        @include te-bg-filter(40px);
        -webkit-transform: scale(1, 0.2);
        height: 80px;
        top: 230px;
        left: 30px;
        border-radius: 50%;
        opacity: 0.2;
      }
      @keyframes fillvalue-bar2 {
        0% {
          transform: rotate(15deg) scale(1, 0);
          opacity: 100;
        }
        80% {
          transform: rotate(15deg) scale(1, 1.3);
          opacity: 100;
        }
        100% {
          transform: rotate(15deg) scale(1, 1);
          opacity: 100;
        }
      }
      @keyframes fillvalue-bar1 {
        0% {
          transform: scale(1, 0);
          opacity: 100;
        }
        80% {
          transform: scale(1, 1.3);
          opacity: 100;
        }
        100% {
          transform: scale(1, 1);
          opacity: 100;
        }
      }
      @keyframes fillvalue-bar3 {
        0% {
          transform: rotate(-15deg) scale(1, 0);
          opacity: 100;
        }
        80% {
          transform: rotate(-15deg) scale(1, 1.3);
          opacity: 100;
        }
        100% {
          transform: rotate(-15deg) scale(1, 1);
          opacity: 100;
        }
      }
      @keyframes winners-trophy {
        0% {
          transform: rotate(-15deg);
        }
        100% {
          transform: rotate(15deg);
        }
      }
      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 100;
        }
      }
    }
    .te-lastgame-teams {
      margin: 20px 50px;
      padding: 0 30px;
      background-color: #f1f2f9;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 220px;
      min-height: 300px;
      .members {
        display: flex;
        flex-direction: column;
        left: 10px;
        .profile-img {
          margin: 0 10px;
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }
      }
    }
  }
}

.te-previous-games-cards {
  // font-family: Raleway, Arial, Helvetica, sans-serif;
    font-family: "Urbanist", sans-serif;
  display: flex;
  flex-wrap: wrap;
  .te-previous-games-card {
    width: 220px;
    background-color: #f1f2f9;
    margin: 10px 10px;
    padding: 0px 10px;
    min-height: 250px;
    .te-previous-game-bar {
      margin: 20px 0px;
      height: 50px;
      display: flex;
      background-color: #f1f2f9;
      text-align: center;
      align-items: flex-end;
      .bar1 {
        background-color: #8d9bff;
        width: 100%;
        position: relative;
        height: 100%;
        border-radius: 5px 5px 0px 0px;
      }
      .bar2 {
        background-color: #8d9bff;
        width: 100%;
        position: relative;
        height: 60%;
        border-radius: 0px 5px 0px 0px;
      }
      .bar3 {
        background-color: #8d9bff;
        width: 100%;
        position: relative;
        height: 40%;
        border-radius: 5px 0px 0px 0px;
      }
    }
    .te-previous-game-details {
      display: flex;
      justify-content: space-between;
    }
    .te-teams-played-arrow {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
  }
  .te-previous-games-teams {
    align-items: center;
    width: 220px;
    background-color: #f1f2f9;
    margin: 10px;
    overflow: scroll;
    min-height: 250px;
    overflow-x: hidden;
    box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.15);
    .profile-img {
      margin: 0 5px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }

  .te-previous-games-teams::-webkit-scrollbar {
    width: 25px;
    height: 3px;
  }
  .te-previous-games-teams::-webkit-scrollbar-button {
    width: 50px;
  }
  .te-previous-games-teams::-webkit-scrollbar-track {
    background: #8769ff4b;
    border-right: 22px #f1f2f9 solid;
    border-top: 22px #f1f2f9 solid;
    border-bottom: 22px #f1f2f9 solid;
  }

  .te-previous-games-teams::-webkit-scrollbar-thumb {
    background: #3c50e0;
    border-right: 22px #f1f2f9 solid;
    padding-bottom: 30px;
  }
}

.bar-filter {
  filter: drop-shadow(0px 0px 3px rgba(131, 158, 208, 0));
}
.bar-filter-active {
  filter: drop-shadow(0px 0px 3px rgba(131, 158, 208, 0.5));
  font-weight: 800;
}
.icon-clicked {
  transform: rotate(180deg);
  transition: all 0.3s ease;
  height: 50%;
}
.icon-unclicked {
  transform: rotate(0deg);
  transition: all 0.3s ease;
  height: 50%;
}

.memberchat-icon {
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

// .te-cam-btn {
//   &.btn-on {
//     background-image: url(../images/memberChat-camon.svg);
//   }
//   &.btn-off {
//     background-image: url(../images/memberChat-camoff.svg);
//   }
// }

// .te-screen-btn {
//   &.btn-on {
//     background-image: url(../images/memberChat-screenon.svg);
//   }
//   &.btn-off {
//     background-image: url(../images/memberChat-screenoff.svg);
//   }
// }

// .te-mic-btn {
//   &.btn-on {
//     background-image: url(../images/memberChat-micon.svg);
//   }
//   &.btn-off {
//     background-image: url(../images/memberChat-micoff.svg);
//   }
// }

.te-cam-btn {
  &.btn-on {
    background-image: url(../images/memberChat-camon.svg);
  }
  &.btn-off {
    background-image: url(../images/memberChat-camoff.svg);
  }
}

.te-screen-btn {
  &.btn-on {
    background-image: url(../images/memberChat-screenon.svg);
  }
  &.btn-off {
    background-image: url(../images/memberChat-screenoff.svg);
  }
}

.te-mic-btn {
  &.btn-on {
    background-image: url(../images/icons/chat_mic_on.svg);
  }
  &.btn-off {
    background-image: url(../images/icons/chat_mic_off.svg);
    
    &[disabled] {
      background-image: url(../images/icons/chat_mic_default.svg);
    }
  }
  &.btn-disabled {
    background-image: url(../images/icons/chat_mic_default.svg);
  }
}

.te-pin-btn {
  &.btn-on {
    background-image: url(../images/icons/chat_pin_active.svg);
  }
  &.btn-off {
    background-image: url(../images/icons/chat_pin.svg);
  }
}


.toast-notifications {
  position: fixed;
  color: white;
  display: flex;
  margin-top: 2px;
  flex-direction: column-reverse;
  .toast-notification {
    background: #15223893;

    @include te-bg-filter(3px);
    display: flex;
    width: fit-content;
    flex-direction: column;
    padding: 10px;
    padding-right: 20px;
    margin-top: 2px;
    align-self: flex-end;
  }
}

.te-bg-darken {
  background-color: rgba(0, 0, 0, 0.2);
}

.te-badge {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-weight: normal;
}

.te-level-gradient {
  background: rgb(0, 255, 66);
  background: linear-gradient(90deg, rgba(0, 255, 66, 1) 0%, rgba(255, 0, 0, 1) 100%);
}

.settings-payment {
  .price-cards {
    width: 100%;
    color: #3c50e0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    img {
      transform: scale(0.6);
      margin-right: 10px;
      filter: drop-shadow(0px 7px 8px rgba(22, 29, 94, 0.219));
    }
    .price-card {
      width: 270px;
      height: 350px;
      display: flex;
      flex-direction: column;
      .price {
        width: 100%;
        height: 35%;
        padding-top: 13px;
      }
      p {
        margin: 8px;
        margin-bottom: 0;
      }
      button {
        margin-top: 10px;
        width: fit-content;
        margin: auto;
      }
    }
    .price-card-1 {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.42) 0%,
        rgba(192, 188, 237, 0.12) 100%,
        rgba(216, 215, 235, 0.29) 100%
      );
      .price {
        background: #e8e6f9;
      }
    }
    .price-card-2 {
      background-color: #f0f5ff;
      .price {
        background: #e5eeff;
      }
    }
    .price-card-3 {
      background: linear-gradient(180deg, #c2def0 0%, rgba(194, 222, 240, 0.32) 100%);
      .price {
        background: #c1d7ef;
      }
    }
    @media only screen and (max-width: 601px) {
        flex-direction: column;
        margin: unset !important;
        justify-content: center;
        align-items: center;
        padding: 15px;
      .price-card {
        width: 100%;
        margin: 10px 0 !important;
      }
    }
  }
}

.chat-leaderboard {
  height: calc(100% - 50px);
  background-color: #51308a;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  overflow-y: scroll;

  @media (min-width: 601px) {
    &::-webkit-scrollbar {
      width: 8px;
    }
    
    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 10px 10px rgba(255, 255, 255, 0.04);
      border: solid 2px transparent;
      border-radius: 10px;
      visibility: hidden;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }
  }

  .te-title {
    color: $textColor2;
    font-size: 16px;
    text-transform: uppercase;
  }
  
}

.te-refresh-btn {
  border: none;
  position: absolute;
  top: 0px;
  right: 0px;
  background: none;
  color: #fff;
}

.te-flex {
  display: flex;
  flex-wrap: wrap;
}

.te-create-game {
    .te-game-card {
      width: 320px;
      height: 320px;

      @media only screen and (max-width: 320px) {
        width: 280px;
      }
      
      @media only screen and (min-width: 601px) {
        width: 350px;
      }

      border: solid 1px rgba(255, 255, 255, 0.5);
      background-color: rgba(255, 255, 255, 0.04);
      img {
        height: 140px;
        object-fit: cover;
        border-radius: 10px 10px 0 0;
      }
    }
    .added{
      border-color: $primaryColor2;
    }
    .add-button{
      border: solid 1px #d8dfee;
      background-color: #232753;
      .button-icon{
        width: 30px;
        margin-right: 10px;
      }
    }
    .added-button,.add-button{
      position: absolute;
      width: 190px;
      left: calc(50% - 95px);
      font-weight: 600;
      bottom: -22px;
      display: flex;
      align-items: stretch;
      .button-icon{
        text-align: center;
        justify-content: center;
      }
      .button-text{
        padding: 8px 0px;
      }
    }
    .added-button{
      padding: 2px;
      border: none;
      background-color: $primaryColor2 ;
      .button-icon{
        background-color: white;
        color:$primaryColor2;
        border-radius: 50%;
        margin-right: 4px;
        width: 40px;
      }
    }
    .slick-dots li.slick-active button:before {
      color: white;
     }
}

.te-select-wrap {
  select {
    background-color: rgba(255, 255, 255, 0.04) !important;
    border: 1px $textColor2 solid;
    color:white;
    &::placeholder{
      color: $textColor2;
      opacity: 0.7;
    }
  }
}

.te-select-box {
  color: #000000a1;
  appearance: none;
  background: url(../images/chevDown-24.png) no-repeat right !important;
  background-color: white !important;
  background-size: 20px !important;
  background-position-x: calc(100% - 10px) !important;
}
.te-select-department-wrap {
  .te-select-department {
    color: #fff !important;
    appearance: none;
    background: url(../images/chevDown-24.png) no-repeat right !important;
    background-color: #202548 !important;
    background-size: 20px !important;
    background-position-x: calc(100% - 10px) !important;
  } 
}


.te-text-question {
  max-width: 840px;
  margin: auto;
  text-align: left;
  font-size: 24px !important;
  line-height: 32px;
}

.te-select-input {
  color: #000000a1;
  appearance: none;
  background: url(../images/chevronDown.png) no-repeat right;
  background-color: white;
  background-size: 20px;
  background-position-x: calc(100% - 10px);
}

.te-occasion-options-width{
  padding: 0 12px;
  padding-right: 40px;
}

.celebrate-container {
  position: relative;

  .canvas-container {
    background-color: black;
    margin: 0 auto;
  }
}

.te-round-info {
  position: relative;
  height: 150px;
  cursor: pointer;
  z-index: 0;
  .te-game-round-card {
    // padding: 5px;
    width: 100px;
    height: 130px;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.6);
    overflow: hidden;

    img {
      width: 100%;
      height: 85px;
      object-fit: cover;
    }

    .flip-card-title {
      font-size: 12px;
      line-height: 1;
      padding: 0 2px;
    }
  }

  .anim-card-1,
  .anim-card-2,
  .anim-card-3 {
    position: absolute;
    left: 50%;
    top: 50%;
    transition: 0.1s ease-in-out;
  }
  .anim-card-3 {
    left: 35%;
    transform: translate(-50%, -50%) rotate(-6.39deg);
  }
  .anim-card-1 {
    z-index: 4;
    transform: translate(-50%, -50%);
  }
  .anim-card-2 {
    left: 65%;
    transform: translate(-50%, -50%) rotate(7.25deg);
  }

  &:hover {
    .anim-card-3 {
      top: 50%;
      left: 20%;
      transform: translate(-50%, -50%) rotate(-6.39deg);
    }
    .anim-card-1 {
      top: 45%;
      z-index: 4;
      transform: translate(-50%, -50%);
    }
    .anim-card-2 {
      top: 50%;
      left: 80%;
      transform: translate(-50%, -50%) rotate(7.25deg);
    }
  }
}

.te-event-btn-container {
  float: none;

  button {
    width: 100%;
  }

  @media only screen and (min-width: 601px) {
    float: right;

    button {
      width: auto;
    }
  }
}

.te-room-select-dropdown {
  background: transparent;
  color: #fff;
  border-radius: 6px;
  border: 1px solid #fff;
  font-size: 14px;
  cursor: pointer;
  display: grid;
  justify-content: space-between;

  @media only screen and (max-width: 600px) {
    border: none;
    justify-content: flex-start;

    .ellipsis {
      overflow: inherit;
    }
  }

  &:hover {
    background: transparent !important;
    color: #fff !important;
  }
}

.te-chat-event-detail {
  display: flex;
  justify-content: center;
  color: #fff;
  padding-top: 15px !important;
  position: relative;

  .te-event-logo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: contain;
    background-color: #fff;
  }

  .te-event-desc {
    padding: 0px 10px;
    font-size: 18px;
    width:100%;

    .te-event-info {
      margin-top: 5px;
      margin-bottom: 15px;
      overflow: hidden !important;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}

.te-feed-card {
  color: white;
  border: solid 1px rgba(255, 255, 255, 0.16);
  background-color: rgba(255, 255, 255, 0.04);
  .game-image{
    width: 100px;
    height: 94px;
    object-fit: cover;
  }

  @media screen and (max-width:'600px'){
    background-color: rgba(39, 44, 96, 0.5);
    flex-direction: column;
    .game-updates{
      .game-updates-list{
        display: block;
      }
      a{
        display: flex;
        justify-content: center;
      }
      & > div{
        border-right:none !important;
      }
      padding:0 !important;
    }
  }
  .game-updates{
    & > div{
      border-right: 1px solid #4c5180;
      height: 100%;
    }
  }
  &:last-child {
    border-bottom: none;
  }
}

.feed-message-sender{
  border-bottom: 1px solid #4c5180;
  .custom-message{
    margin:0 8px 8px 0;
    border: 1px solid $primaryColor2;
    color: $primaryColor2;
    word-break: break-word;
    cursor: pointer;
    &:hover{
      background-color: $primaryColor2;
      color: white;
      transition: all 0.2s ease-in;
    }
  }
}

.te-sub-container {
  color: #fff;
  padding: 20px 16px;
  min-height: 100vh;
  @media only screen and (min-width: 601px) {
    padding: 25px 25px;
  }

  @media only screen and (min-width: 993px) {
    padding: 40px 50px;
  }
  .te-scrollbar {
    height: 150px;
    overflow-y: auto;
    width: 100%;
    text-align: center;
  }

  .te-scrollbar::-webkit-scrollbar-track
  {
  	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  	border-radius: 10px;
  }

  .te-scrollbar::-webkit-scrollbar
  {
    height: 10px !important;
  	width: 4px;
  	background-color: #F5F5F5;
    border-radius: 10px;
  }

  .te-scrollbar::-webkit-scrollbar-thumb
  {
  	border-radius: 10px;
    background-image: -webkit-gradient(linear,
    left bottom,
    left top,
    color-stop(0.44, #4A2986),
    color-stop(0.44, #4A2986));
  }

  .te-poll-width{
    width: 50%;
    margin: auto;
    @media only screen and (max-width: 1300px) {
      width: 35%;
    }
    @media only screen and (max-width: 601px) {
      width: 80%;
    }
  }
  .te-poll-refresh-btn{
    border: none;
    background: none;
    color: #fff;
    padding-right: 2%;
  }
  .te-poll-votes{
    text-align: left;
    padding-left: 15px;
    font-size: 15px;
  }
  .te-poll-card{
    border: solid 1px #225274;
    background-image: linear-gradient(to right, #5d79b6, #26838f);
  }
  .te-poll-user-options{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .te-poll-input-wrapper{
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  .te-poll-btn-pad {
    padding: 8px 10px;
    float: right;

    @media only screen and (min-width: 601px) {
      padding: 8px 40px;
    }
  }

  .te-poll-wrapper {
    border-radius: 10px;
    border: solid 1px rgba(255, 255, 255, 0.16);
    background-color: rgba(255, 255, 255, 0.04);
    margin: 0 5px;

    .slick-arrow {
      &::before {
        content: '';
      }
      .te-poll-arrow {
        background: url(../images/icons/arrow-left-circle.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 30px;
        height: 30px;
        display: block;

  
        &.next {
          transform: rotate(180deg);
          @media only screen and (max-width: 600px) {
            margin-left: -6px;
          }
        }

        &.prev {
          @media only screen and (max-width: 600px) {
            margin-left: 8px;
          }
        }
      }
      &.slick-next {
      margin-right: 10px;
      }
  
      &.slick-disabled {
        .te-poll-arrow {
          opacity: 0.2;
          cursor: not-allowed;
        }
      }
    }
  }

  .te-poll-btn-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .te-poll-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 6;

    input {
      border-radius: 12px;
      border: solid 1px rgba(14, 15, 47, 0.25);
      padding: 8px 16px;
      margin: 10px 0;
      max-width: 100%;
  
      &[disabled] {
        background-color: #f2f2f2;
      }
    }
  }

  .te-poll-modal-main {
    z-index: 10;
    position: fixed;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);  
    background-image: linear-gradient(238deg, #373179, #2e296a, #1e1a4c);
    width: 75vW;
    max-width: 800px;
    max-height: 80vh;
    color:white !important;
    height: auto;
    overflow-y: auto;
    padding: 6vmin 3vmax !important;
    .te-text{
      color:$primaryColor3
    }
    .button-width{
      font-weight: 600;
      width: 200px;
      margin: 10px 0;
    }

    @media only screen and (max-width: 600px) {
      width: 92vW;
    }

    @media only screen and (max-width: 425px) {
      height: 90%;
    }
  }

  .te-poll-option-input{
    background: transparent;
    border: none !important;
    border-bottom: 1px solid #ffffff !important;
    border-radius: unset !important;
    color: #ffffff;
    caret-color: #ffffff;
  }


  .te-heading {
    color: #798bfc;
    font-size: 32px;
    font-weight: 300;
    font-family: "Raleway","sans-serif";
    margin-bottom: 10px;
  }

  .te-sub-heading {
    font-size: 14px;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.7);
    font-family: "Raleway","sans-serif";
  }

  .te-group-element {
    padding-top: 0px;
    padding-bottom: 6px;
  }

  .page {
    text-decoration: none;
    text-transform: uppercase;
    font-family: "Raleway","sans-serif";
    color: #fff;
    opacity: 0.5;
    font-weight: bold;
    margin: 0px 15px;
    margin-right: 20px;

    &.te-selected {
      opacity: 1;
      padding-bottom: 6px;
      background: linear-gradient(to right, $primaryColor2, $primaryColor1);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      border-width: 0px;
      border-bottom-width: 3px;
      border-style: solid;
      border-image: linear-gradient(to right, $primaryColor2, $primaryColor1) 1;
      border-radius: 0px !important;
      color: #3c50e0 !important;
      font-weight: bold;
    }
  }

  .te-banner {
    min-height: 140px;
    height: 140px;
    background-color: transparent;
    .te-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
    }
    // background-image: url(../images/banners/te_banner_1.png);
    background-size: cover;
    font-size: 30px;
    font-weight: bold;
    color: #fff;

    &.banner_img_1 {
      background-image: url(../images/banners/te_banner_1.png);
    }

    &.banner_img_2 {
      background-image: url(../images/banners/te_banner_2.png);
    }
    &.banner_img_3 {
      background-image: url(../images/banners/te_banner_3.png);
    }
    &.banner_img_4 {
      background-image: url(../images/banners/te_banner_4.png);
    }
    &.banner_img_5 {
      background-image: url(../images/banners/te_banner_5.png);
    }
    &.banner_img_6 {
      background-image: url(../images/banners/te_banner_6.png);
    }
    &.banner_img_7 {
      background-image: url(../images/banners/te_banner_7.png);
    }
    &.banner_img_8 {
      background-image: url(../images/banners/te_banner_8.png);
    }
    &.banner_img_9 {
      background-image: url(../images/banners/te_banner_9.png);
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1px solid #fff;
}

.switch-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .switch-slider {
  background-color: #000;
}

input:focus + .switch-slider {
  box-shadow: 0 0 1px #000;
}

input:checked + .switch-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.switch-slider.round {
  border-radius: 34px;
}

.switch-slider.round:before {
  border-radius: 50%;
}

.te-event-list-tab {
  padding: 0px 15px;
  @media only screen and (min-width: 601px) {
    padding: 0px 50px;
  }
}

.te-countdown {
  color: #fff;
  font-size: 32px;
  z-index: 3;
  padding-top: 8px;
}

// .te-text  {
//   color: #fff;
// }

.theme-dark {
  background: #0E0F2F;
}

.theme-light {
  .team-engage-header {
    background-color: #0E0F2F;
  }
  .app-bg-image,.bg-theme {
    background: #fff;

    .te-selected-games {
      background: #fff;

      .headline-text-large,
      .te-game-text-content {
        color: #444;
      }
    }
  }

  .te-chatbox {
    background: #f2f2f2;
    border: 1px solid #d0cdcd;
    .accordion {
      color: black;
    }
    .chatbox-content {
      .videochat-component {
        background: #fff;
        .t-member-list {
          background-color: #f2f2f2;
          color: #121212;
        }
      }
    }

    .icon-list {
      background-color: #e6e6e6;
    }

    .chat-component {
      background-color: #fff;

      .chat-messages {
        .message {
          background-color: #f2f2f2;
          color: #121212;
        }
      }
    }

    .send-message {
      input {
        background-color: #fff;
        color: #444;
      }
    }

    .chat-leaderboard {
      background-color: #fff;
      color: #444;
    }

    .chat-slider {
      background-color: #fff;
      border: 1px solid #d0cdcd;

      .chat-slider-option-active {
        background-color: #3c50e0;
        color: #fff;
      }
    }

    .te-room-select-dropdown {
      background: #fff;
      border: 1px solid #3c50e0;
      color: #444;
    }

    .te-chat-event-detail {
      color: #121212;

      .te-event-logo {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        object-fit: contain;
        background-color: #fff;
      }

      .te-event-desc {
        padding: 0px 10px;
        font-size: 18px;

        .te-event-info {
          color: #666666;
        }
      }
    }
  }

  .font-cursive {
    color: #444 !important;
  }

  .micro-game-overlay {
    .font-cursive {
      color: #fff !important;
    }

    .te-game-text-content {
      color: #fff;
    }
  }

  .te-question {
    background: white;
    border: 1px solid #d0cdcd;
    .te-question-progress {
      background: rgb(212, 212, 212);
    }
    .te-box-white-alt {
      border: 1px solid #d0cdcd;
    }
  }

  .te-summary {
    background: white;
    border: 1px solid #d0cdcd;
    .te-text-white {
      color: inherit;
    }
  }

  .te-card {
    border: 1px solid #d0cdcd;
    background-color: #fff;
    background-image: none !important;

    .te-card-content-block {
      background-color: #f2f2f2;
      border-radius: 10px;
      color: #444;
      font-size: 14px;

      .top-teams {
        background: none;
      }
    }

    .te-game-type-tag {
      background: #f2f2f2 !important;
    }
  }

  .te-dashboard {
    background: #fff;
    color: #121212;

    .te-game-text-content {
      color: #121212;
    }

    .te-game-card {
      background: #f2f2f2;
      color: #121212;
      border: 1px solid #d0cdcd;
    }
  }

  .te-game-counter {
    color: #121212;
  }

  .te-counter-panel {
    background: #fff;
  }

  .te-tab-button {
    color: #121212;

    &.te-active {
      color: #fff;
    }
  }

  .te-feed-card {
    color: #121212;
  }

  .te-selected-games {
    .top-teams {
      background: #f2f2f2;
      border-radius: 8px;
    }

    .te-coinwon {
      background-color: #f2f2f2;
    }
  }

  .te-gallery-body {
    background: #fff;
  }

  .te-celebrate {
    background: #f2f2f2;
  }

  .te-game-play {
    background: #f2f2f2;

    .te-score-card {
      background: #fff;
    }
  }

  .te-game-text-content {
    color: #121212;
  }

  .media-gallery-msg{
    display: flex;
    @media screen and (max-width:"500px") {
      flex-direction: column;
    }
  }

  .te-sub-container {
    color: #121212;
  }

  .te-game-l-board {
    .te-bar {
      .te-game-text-content {
        color: #fff;
      }
    }
  }

  .te-cam-btn {
    &.btn-on {
      background-image: url(../images/memberChat-camon-1.svg);
    }
    &.btn-off {
      background-image: url(../images/memberChat-camoff-1.svg);
    }
  }

  .te-screen-btn {
    &.btn-on {
      background-image: url(../images/memberChat-screenon-1.svg);
    }
    &.btn-off {
      background-image: url(../images/memberChat-screenoff-1.svg);
    }
  }

  .te-mic-btn {
    &.btn-on {
      background-image: url(../images/memberChat-micon-1.svg);
    }
    &.btn-off {
      background-image: url(../images/memberChat-micoff-1.svg);
    }
  }

  .te-white-header {
    background-color: white;
  }
  .app-bg-land {
    .team-engage-header {
      background-color: white;
    }
  }

  .te-lobby-theme {
    background: #f2f2f2;

    .te-lobby-winners-container {
      color: #121212;
    }

    .te-lobby-card {
      background: #fff;
      color: #121212;
      button {
        color: #fff;
      }
    }

    .te-lobby-card-theme {
      background: #fff;
      color: #121212;
    }

    .te-lobby-tabs {
      color: #121212;
    }
  }

  .te-slider,
  .slider-range {
    color: #121212;
  }

  .stats-fun-games {
    background-color: #f2f2f2;
    color: #121212;

    .player {
      color: #fff;
    }
    .game-name {
      color: #fff !important;
    }

    .stats-button {
      color: #3c50e0 !important;
      &.te-button-blue {
        color: #fff !important;
      }
    }
    .pop-up {
      background-color: #f2f2f2;
      color: #121212;
    }
  }

  .timer-coin {
    background: #656565;
    border-radius: 8px;
  }

  .te-button-blue {
    color: #fff !important;
  }

  .te-bottom-border {
    border-bottom: 1px solid #f2f2f2;
  }

  .t-form-team {
    color: #121212;
  }

  .te-back-arrow {
    color: #121212;
  }

  .te-game-play {
    .modal-main {
      background-color: white !important;
    }
  }
  .te-text {
    color: #121212;
  }
}


.coin-won {
  position: absolute;
  border-radius: 50%;
  background-image: url("../images/coins-left.png");
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 1px;
  left: 90px;
  z-index: 1000;
}

.chat-box-expand{
  width: 100%;
}

.chat-box-collapse{
  width: 0;
  .te-chat-event-detail{
    visibility: hidden;
  }
  @media only screen and (min-width: 601px) {
    width: 80px;
    .te-chat-event-detail{
      visibility: unset;
    }
  }
}

.chat-expand-content{
  padding-right: 0;
}

.chat-collapse-content{
  padding-right: 0;
  @media only screen and (min-width: 601px) {
    padding-right: 80px;
  }
}

@media screen and (max-width: 600px) {
  .hide-widget-play{
    .te-chatbox-collapsed{
      bottom:-200px !important;
    }
  }
}

#te-chat-box {
  @media only screen and (min-width: 601px) {
    display: block;
    background: transparent;
  }

  @media screen and (max-width: 600px) {
    // transition: none;
    // animation: opac 0.4s;
  }

  .chatbox-widget {
    position: absolute;
    display: none;
    z-index: 3;
    width: 100%;
    bottom: 0px;
    justify-content: center;
    height: 20px;

    img {
      width: 56px;
      height: 56px;
      margin-top: -38px;
    }
  }

  @media only screen and (max-width: 600px) {
    .te-chatbox-collapsed {
      position: fixed;
      width: 100vw;
      height: 20px;
      bottom: 0;
      transition: bottom 0.3s ease 0s, height 0.3s ease 0s;

      .chatbox-widget {
        display: flex;
      }
    }

    .te-chatbox-expanded {
      .chatbox-widget {
        display: none;
      }
    }
  }

  &.te-no-header {
    $headerHeight: 0px;
    top: $headerHeight;

    .chat-content-block {
      height: calc(100vH - #{$headerHeight} - 66px - 61px);

      @media screen and (max-width: 600px) {
        height: calc(100vH - #{$headerHeight} - 49px - 61px);
      }
    }
  }

  position: fixed;
  bottom: 0px;
  right: 0px;
  transition: width 0.3s ease 0s, right 0.3s ease 0s;
  z-index: 7;
  top: $headerHeight;
  background-color: rgb(240, 240, 240);
  transform: translate3d(0px, 0px, 0px);
}


.te-celebrate-props {

  .prop-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 5px;
  }

  .prop-item-btn {
    background: none;
    outline: none;
    border: none;
    font-size: 16px;
    padding: 5px 10px;
    width: 100%;
    color: #9dc2ff;
    position: relative;
    text-align: left;

    &:hover {
      background: none !important;
      color: $primaryColor2 !important;
      cursor: pointer;
      &::after {
        background-image: url('../images/icons/accordion-up.svg');
        transform: rotate(180deg);
        top: 10px;
      }
    }

    &::after {
      content: '';
      width: 10px;
      height: 10px;
      background-image: url('../images/icons/accordion-down.svg');
      position: absolute;
      right: 0;
      background-repeat: no-repeat;
      background-size: 10px;
      top: 15px;
    }

    &.te-active {
      color: $primaryColor2;

      &::after {
        background-image: url('../images/icons/accordion-up.svg');
      }
    }
  }

  .te-prop-item {
    border: 2px solid transparent;
    background-color: #c0cdff;
    margin: 5px 5px;
    width: 120px;
    height: 80px;
    position: relative;

    .prop-bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.te-selected {
      border: 2px solid #D7449A
    }

    .te-prop-img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }

    .ion-checkmark-circled {
      color: $primaryColor2;
      margin: 2px;
      cursor: pointer;
    }

    &[disabled] {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
  
}

.create-event-wizard {
  margin: 0 15px;
  @media only screen and (min-width: 601px) {
    margin: 0 50px;
    padding-bottom: 10px;
  }
  
  .te-step{
    width: 40px;
    height: 40px;
    font-weight: 800;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .step-circle-active{
    background: linear-gradient(to right, $primaryColor1, $primaryColor2);
    padding: 7px;
  }
  .step-circle-inactive{
    background: $primaryColor4;
    border: 2px solid $textColor2;
    color: $textColor2;
    padding: 5px;
  }
  .step-line-active{
    background: $primaryColor2;
  }
  .step-line-inactive{
    background: $textColor2;
  }
}

.invite-game {
  .checkmark-icon {
    width: 60px;
    height: 60px;
    background: linear-gradient(to right, $primaryColor1, $primaryColor2);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
  }
}

.bg-theme {
  background-color: $primaryColor4;
  .headline-text-large{
    color: $textColor1;
  }
}

.te-content-container {
  padding: 20px 0;
  border-radius: 30px;

  @media only screen and (min-width: 601px) {
    margin: 30px 0px;
    box-shadow: -5px 5px 0 0 #4c3575;
    border: solid 1px rgba(255, 255, 255, 0.05);
    background-image: linear-gradient(233deg, #262d6e 2%, #181c43 48%, #181c43 2%);
  }
}

#displayed-canvas {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.04);;
}

.moment-props-container, .moment-canvas-container {
  background-color: rgba(255, 255, 255, 0.04);
  border: solid 1px rgba(255, 255, 255, 0.16);
  border-radius: 10px;
  padding: 15px;
}

.te-celebrate-container-block {
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
  }
}

.moment-props-container {
  width: 100%;
  margin-right: 0px;
  margin-bottom: 20px;
  margin-top: 20px;

  @media only screen and (min-width: 601px) {
    width: 292px;
    margin-right: 20px;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  &.chatbox-epanded {
    width: 160px;


    .prop-container {
      max-height: 300px;
      overflow-y: scroll;

      @media (min-width: 601px) {
        &::-webkit-scrollbar {
          width: 8px;
        }
        
        &::-webkit-scrollbar-thumb {
          box-shadow: inset 0 0 10px 10px rgba(255, 255, 255, 0.04);
          border: solid 2px transparent;
          border-radius: 10px;
          visibility: hidden;
        }
  
        &:hover {
          &::-webkit-scrollbar-thumb {
            visibility: visible;
          }
        }
      }
    }
  }
}

.moment-canvas-container {
  // margin-left: 20px;
}

.te-chatbox-icons {
  background: none;
  outline: none;
  border: none;
  padding: 5px;
  margin: 15px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;

  &.te-video-icon {
    background-image: url(../images/icons/video-off.svg);

    &:hover {
      background-image: url(../images/icons/video-off-h.svg);
    }
    &.te-active {
      background-image: url(../images/icons/video-on.svg);
    }
  }

  &.te-audio-icon {
    background-image: url(../images/icons/mic-off.svg);

    &:hover {
      background-image: url(../images/icons/mic-off-h.svg);
    }
    &.te-active {
      background-image: url(../images/icons/mic-on.svg);
    }
  }

  &.te-screen-icon {
    background-image: url(../images/icons/screenshare-off.svg);

    &:hover {
      background-image: url(../images/icons/screenshare-off-h.svg);
    }
    &.te-active {
      background-image: url(../images/icons/screenshare-on.svg);
    }
  }

  &.te-message-icon {
    background-image: url(../images/icons/message.svg);
    

    &:hover {
      background-image: url(../images/icons/message-h.svg);
    }
    &.te-active {
      background-image: url(../images/icons/message-selected.svg);
    }
  }

  &.te-people-icon {
    background-image: url(../images/icons/people.svg);

    &:hover {
      background-image: url(../images/icons/people-h.svg);
    }
    &.te-active {
      background-image: url(../images/icons/people-selected.svg);
    }
  }

  &.te-leaderboard-icon {
    background-image: url(../images/icons/leaderboard.svg);

    &:hover {
      background-image: url(../images/icons/leaderboard-h.svg);
    }
    &.te-active {
      background-image: url(../images/icons/leaderboard-selected.svg);
    }
  }
}

.te-chat-tab-item-contianer {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);

  .te-count {
    font-size: 18px;
    font-weight: 200;
    position: absolute;
    top: 28px;
  }

  .te-chat-tab-item {
    text-align: center;
    &.te-active {
      box-shadow: 0px 4px 0px -1px #f14f85;
      margin-bottom: 1px;

      .te-count {
        color: #f14f85;
      }
    }
  }
}

.te-scroll {
  overflow: scroll;
  padding:8px 0px !important;
  &::-webkit-scrollbar { width: 0 !important }
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.te-chat-top {
  display: flex;
  justify-content: center;
  color: #fff;
  padding: 10px 25px;
  position: relative;
  padding-top: 25px;

  @media only screen and (max-width: 600px) {
    background: rgba(0, 0, 0, 0.2);
    padding-top: 10px;
  }

  .w3-dropdown-click {
    &:hover {
      background: transparent !important;
    }
  }
}

.te-chat-close-button {
  background: none;
  outline: none;
  border: none;
  color: #fff;
  padding: 0px;

  img {
    height: 13px;
    margin: 5px;
  }
}

.te-upload-btn-container {
  display: flex;
  
  @media only screen and (max-width: 600px) {
    justify-content: center;
    width: 100%;
  }
}

.te-celebrate-btn-cointainer {
  margin-top: 5px;

  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    button {
      margin-right: 0px !important;
    }
  }
}


#macaw_widget {

  .w3-modal {
    background: transparent !important;
    color: rgba(255, 255, 255, 0.9) !important;
    font-family: "Raleway", "sans-serif";

    #mc-arrow {
      border-left: 10px solid #4b2a86 !important;
    }

    .w3-modal-content {
      background: #4b2a86 !important;
      color: #fff !important;
      width: 500px;

      #close-btn {
        font-size: 24px !important;
        padding: 0px 20px !important;

        &:hover {
          color: #fff !important;
          background: transparent !important;
        }
      }

      #flow-content-title, #flow-title {
        color: rgba(255, 255, 255, 0.9) !important;
        font-family: "Raleway", "sans-serif";
        font-weight: 800;
      }

      .macaw-markdown-content {
        font-size: 14px;
      }
    }

    .w3-border-top {
      border-top: 1px solid rgba(255,255,255,0.15) !important;
    }

    #back-btn {
      font-size: 12px !important;
      color: #f14f85 !important;
      background: transparent !important;
      border: none !important;
      padding: 4px 10px !important;
      margin-right: 10px !important;
      box-shadow: 0 0 0 1px #f14f85 inset;
    }
    
    #next-btn {
      font-size: 12px !important;
      color: #fff !important;
      background-image: linear-gradient(to right, $primaryColor1, $primaryColor2) !important;
      border: none !important;
      padding: 4px 10px !important;
    }

    #view-faq {
      color: #f14f85 !important;
    }

    #powered-by-macaw {
      color: rgba(255, 255, 255, 0.9) !important;
    }
  }
}

.status-btn {
  padding-bottom: 8px;
  &.status-btn-active {
        background: linear-gradient(to right, #d6449c, #5E17E1);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        border-width: 0px;
        border-bottom-width: 3px;
        border-style: solid;
        border-image: linear-gradient(to right, #d6449c, #5E17E1) 1;
        border-radius: 0px !important;
  }
}

.te-scroll-menu {
  overflow-x: scroll;
  
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px rgba(255, 255, 255, 0.04);
    border: solid 2px transparent;
    border-radius: 10px;
    visibility: hidden;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
}

.trivia-status {
  .te-progress {
    height: 8px;
    cursor: pointer;
  }
  .trivia-status-popup {
    backdrop-filter: blur(10px);
    border: solid 1px rgba(77, 82, 132, 0.5);
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top:0;
    right:0;
    border-radius: 0px 10px 10px 10px;
    z-index: 2;
  }
}

.te-room-name {
  display: inline-block;
  @media only screen and (max-width: 390px) {
    max-width: 200px;
  }

  @media only screen and (max-width: 320px) {
    max-width: 165px;
  }

  max-width: 250px;  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  text-align: left;
}

.te-alert {
  max-width: 100%;
  box-shadow: 2px 3px 2px 0 rgba(34, 82, 128, 0.04);
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  padding: 8px 15px;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  position: relative;

  &.success {
    background-color: #51bd6b;
  }

  &.alert {
    background-color: #ed813b;
  }

  &.info {
    background-color: #4bb3d5;
  }

  &.warning {
    background-color: #d05547;
  }

  h3 {
    color: #fff;
    font-size: 14px;
    font-weight: 800;
    margin: 0;
  }

  .icon-alert {
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 15px;

    &.success {
      background-image: url(../images/icons/alerts/check-font-awesome.svg);
    }

    &.alert {
      background-image: url(../images/icons/alerts/exclamation-circle-font-awesome.svg);
    }
  
    &.info {
      background-image: url(../images/icons/alerts/info-circle-font-awesome.svg);
    }
  
    &.warning {
      background-image: url(../images/icons/alerts/exclamation-triangle-font-awesome.svg);
    }
  }

  .icon-close {
    width: 10px;
    height: 10px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 15px;
    background-image: url(../images/icons/close.svg);
    position: absolute;
    right: 0;
    top: 10px;
    cursor: pointer;
  }
}

.te-quiz-page {
  .te-create-game {
    .te-game-card {
      border: solid 1px rgba(255, 255, 255, 0.2);
      background-color: rgba(255, 255, 255, 0.1);

      &.complete {
        opacity: 0.6;
      }

      .te-card-description {
        margin: 0px;
        margin-top: 5px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        white-space: pre-wrap;
      }
      img {
        border-radius: 6px 6px 0 0;
      }
    }
  }

  .te-quiz-create-form {
    max-height: 500px;
    overflow-y: scroll;
    padding: 20px 20px;

    @media only screen and (min-width: 601px) {
      padding: 20px 25px;
    }

  }
}

.te-room-select-dropdown-content {
  border-radius: 5px;
  overflow: hidden;
  max-height: 500px;
  overflow-y: scroll;
}

.te-canvas-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.6);
}

.te-canvas-container {
  width: 800px;
  height: 398px;
  margin: 0 auto;
  position: relative;

  .te-circular-progress-container {
    width: 110px;
    height: 110px;
    position: absolute;
    bottom: 20px;
    right: 20px;

    .circular-progressbar-content {
      font-size: 20px;
      font-weight: 800;

      .te-step-count-bg {
        width: 25px;
        height: 36px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        margin: 0 auto;

        &.three {
          background-image: url(../images/icons/counter-3.svg);
        }

        &.two {
          background-image: url(../images/icons/counter-2.svg);
        }
        &.one {
          background-image: url(../images/icons/counter-1.svg);
        }
      }
    }
  }
}

.te-occasion-create {
  width: 400px;
  z-index: 10;
  border-radius: 30px;
  padding: 15px 10px;
  max-width: 95%;
}

.te-occasion-create, .te-quiz-page, .input-container {
  input, textarea {
    border-radius: 12px;
    border: solid 1px rgba(14, 15, 47, 0.25);
    padding: 8px 16px;
    margin-bottom: 20px;
    max-width: 100%;

    &[disabled] {
      background-color: #f2f2f2;
    }
  }

  label {
    color: #0e0f2f;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 5px;
    display: inline-block;
  }
}

.te-form-action-container {
  margin-top: 30px;
  margin-left: -20px;
  margin-right: -20px;
  padding-top: 20px;
  border-top: solid 1px #e5e5eb;

  @media only screen and (min-width: 601px) {
    margin-left: -25px;
    margin-right: -25px;
  }
}

.quiz-result-summary {
  @media only screen and (min-width: 601px) {
    max-width: 80%;
    margin: 0 auto;
  }
}

.te-coins-won{
  font-family: Raleway;
  font-size: 50px;
  font-weight: 800;
  color: #ffdb70;
}

#team_answers {
  .te-team-name {
    text-align: center;

    @media only screen and (min-width: 601px) {
      text-align: right;
    }
  }
  .te-award-point-container {
    text-align: center;
  
    @media only screen and (min-width: 601px) {
      text-align: left;
    }
  
    input {
      margin-top: 15px;
      @media only screen and (min-width: 601px) {
        margin-top: 0px;
        margin-left: 15px;
      }
    }
  }
}

.te-dropdown-content {
  backdrop-filter: blur(10px);
  border: solid 1px rgba(255, 255, 255, 0.24) !important;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  max-height: 200px;
  overflow-y: scroll !important;
  z-index: 5;

  @media (min-width: 601px) {
    margin-right: -13px;

    &::-webkit-scrollbar {
      width: 8px;
    }
    
    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 10px 10px rgba(255, 255, 255, 0.04);
      border: solid 2px transparent;
      border-radius: 10px;
      visibility: hidden;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }
  }
}

.te-status-green {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #00a94b;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.te-gallery-select {
  position: absolute;
  width: 20px;
  height: 20px;
  background: white;
  color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 15px;
  top: 15px;
  border: 1px solid grey;
  cursor: pointer;
}

.te-gallery-event-card {
  &.te-img-selected {
    .te-gallery-event-img {
      border: 2px solid #d6449c;
    }

    .te-gallery-select {
      background: #d6449c;
      color: white;
      border: 1px solid #fff;
    }
  }
}

.te-feed-gallery-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.te-feed-moment-card {
  display: inline-flex;
  flex-direction: column;
  padding-right: 10px;
  position: relative;
  margin-top: 10px;

  img {
    width: 120px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
    margin-top: 4px;
    cursor: pointer;
  }

  &.te-feed-image {
    width: 100%;
    @media (min-width: 601px) {
      width: 50%;
    }

    &:only-child {
      width: 100%;
    }
    img {
      width: 100%;
      height: auto;
    }
  }

  .more-btn {
    cursor: pointer;
    font-size: 14px;
    margin-top: 15px;
  }
}

.te-flex-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100% !important;
}


.te-input-error {
  border: 1px solid red !important;
}

.te-occasion-list-block {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  max-height: 350px;
  overflow-y: scroll;

  .te-occasion-card {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;

    cursor: pointer;
    img {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border: 1px solid #ccc;
      border-radius: 8px;
    }

    .te-title {
      margin: 0px 5px;
      font-weight: bold;
      font-size: 14px;
      text-transform: capitalize;
    }
    .te-description {
      margin: 0px 5px;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.te-modal-overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.terms-subtitle {
  font-weight: 500;
}
.policy-container {
  padding-bottom: 15px;
}

.te-event-calendar-card {
  background: #4a2a86;
  color: white;

  &.te-private-evt {
    cursor: not-allowed !important;
    opacity: 0.6;
  }

  &:hover {
    background: #f14f85 !important;
  }

  &.complete {
    text-decoration: line-through;
    background: grey;

    &:hover {
      text-decoration: line-through;
      background: lightgrey !important;
    }
  }
}

.te-festival-card {
  padding-left: 18px;
  background: #26838f !important;
  &:hover {
    background: #44d7d2 !important;
  }
  color: white;
  cursor: pointer;

  &::after {
    content: '\01F389' !important;
    position: absolute;
    left: 2px;
    top: 0;
  }
}

.te-birthday-card {
  padding-left: 18px;
  background: #f14f85 !important;
  &:hover {
    background: #e2488d !important;
  }
  color: white;
  cursor: pointer;

  &::after {
    content: '\01F382' !important;
    position: absolute;
    left: 2px;
    top: 0;
  }
}

.te-work-anniversary-card {
  padding-left: 18px;
  background: #2a4499 !important;
  &:hover {
    background: #4aa0f3 !important;
  }
  color: white;
  cursor: pointer;

  &::after {
    content: '\01F44F' !important;
    position: absolute;
    left: 2px;
    top: 0;
  }
}

.te-event-calendar-card, .te-festival-card, .te-birthday-card, .te-work-anniversary-card {
  white-space: initial !important;
  word-break: break-all !important;
}

.te-settings-add-user-list {
  display: inline-block;
}
.te-csv-wrapper {
  width: 160px;
  height: 45px;
  display: flex;
  position: relative;

  label {
    position: absolute;
    width: 100%;
    text-align: center;
  }
}
.te-lavender-add-user-csv {
  background: #edf0ff !important;
  padding: 0vmin 0vmin 5vmin 0vmin;
  border-left: none;
  label{
    display: inline-block;
    margin:10px 0px 5px 0px;
  }
}

.te-table-container-csv {
  background-color: rgba(255, 255, 255, 0.04);
  border-radius: 15px;
  padding: 0px 15px 30px;
  margin-bottom: 20px;
}

.delete-btn {
    color: white;
    background-color: #e04949;
    border-radius: 10px;
    border: none;
    width: 75px;
    font-size: 12px;
    padding: 5px;
    cursor: pointer;
}

.te-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.te-scroll-y {
  @media (min-width: 601px) {
    margin-right: -13px;

    &::-webkit-scrollbar {
      width: 8px;
    }
    
    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 10px 10px rgba(255, 255, 255, 0.04);
      border: solid 2px transparent;
      border-radius: 10px;
      visibility: hidden;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }
  }
}

.te-group-select {
  outline: none !important;
  border: none !important;
  &:hover {
    background: transparent !important;
  }
  .te-group-list-item {
    padding: 5px 10px;
    cursor: pointer;

    img {
      width: 30px;
      margin-right: 5px;
    }
  }
}

.te-error-message {
  color: red;
}
.te-edit-outlined-button {
  padding: 3px 15px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  margin-top: 4px;
  margin-right: 0;
}

.radio-div {
  overflow: hidden;
  .radio-group {
    float: left;
    clear: none;
  }
  
  label {
    float: left;
    clear: none;
    display: block;
    padding: 0px 1em 0px 8px;
  }
  
  input[type=radio],
  input.radio {
    float: left;
    clear: none;
    margin: 6px 0 0 2px;
  }
}

.active-label, .inactive-label {
  background-color: white;
  color: green;
  border-radius: 4px;
  position: absolute;
  right: 12px;
}
.inactive-label {
  color: red;
}

.te-user-card {
  position: relative;
  strong {
    color: #666;
  }

  .te-profile-img {
    border: 1px solid #ddd;
    border-radius: 50%;
    width: 50px;
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
  .te-profile-tag {
    border: 1px solid #ddd;
    background: #b4bcf5;
    border-radius: 50%;
    text-decoration: none;
    color: #3c50e0;
    width: 50px;
    height: 50px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }

  .te-centered-contents {
    @media (min-width: 601px) {
      display: flex;
      justify-content: space-between;
    }
  }
}

.te-poll-slider-conteiner {
  margin: 0px;
  @media (min-width: 601px) {
    margin: 0px 40px;
  }
}
