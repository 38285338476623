@import "./w3.css";
@import "./common.scss";
@import "./variables.scss";

.font-sans {
  // font-family: Work Sans, sans-serif;
    font-family: "Urbanist", sans-serif;
}

.flip {
  //padding:35px;
  .flip-body {
    position: relative;
    border-radius: 20px;
    text-align: center;
    background: rgba(22, 36, 71, 0.4);

    @include te-bg-filter(30px);
    min-height: 90vh;
    // background-image: url("https://cgs-cdn.s3.ap-south-1.amazonaws.com/team-engage/uploads/2021/07/16104035/fun_game_dash.png");
    // background-size: 100% auto;
    color: #f4f5f7;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width:600px) {
    min-height: 65vh;
    }
  }
  .playground {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    perspective: 1000px;
    .flip-card {
      width: 117px;
      user-select: none;
      height: 117px;
      padding: 10px;
      box-sizing: border-box;
      text-align: center;
      margin: 10px;
      transition: 0.6s;
      transform-style: preserve-3d;
      position: relative;
      div {
        backface-visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        //background-color: rgba(33, 38, 51, 0.6);

        @include te-bg-filter(100pxp);
      }
      .front {
        font-size: 50px;
        line-height: 104px;
        cursor: pointer;
        color: darken(#f4f5f7, 50%);
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("https://cgs-cdn.s3.ap-south-1.amazonaws.com/team-engage/uploads/2021/04/16080241/flip_card_close.png");
        background-size: 117px 117px;
        transition: 0.6s;
        //background: -webkit-linear-gradient(to top, #FFFFFF, #ECE9E6);
        //background: linear-gradient(to top, #FFFFFF, #ECE9E6);
      }
      .back {
        background-image: url("https://cgs-cdn.s3.ap-south-1.amazonaws.com/team-engage/uploads/2021/04/16080242/flip_card_open.png");
        background-size: 117px 117px;
        transform: rotateY(180deg);
        line-height: 117px;
        transition: 0.6s;
        img {
          vertical-align: middle;
          width: 70%;
        }
      }
      .card-value {
        font-size: 14px;
        padding: 5px;
        line-height: 1.4;
        justify-content: center;
        display: flex;
        align-items: center;
        word-break: break-word;

        .mg-card-img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      &.reveal {
        transform: rotateY(180deg);
      }
      &.matched {
        transform: rotateY(180deg);
        .back {
          box-shadow: 0 0 0 2px rgba(#000, 0.05) inset;
          animation: selected 0.8s 0s ease 1;
          animation-fill-mode: both;
          opacity: 0.2;
        }
      }

      &.matcher-game {
        transform-style: unset;
        .front {
          transform: none;
          visibility: visible;
        }
        .back {
          transform: none;
          visibility: hidden;
        }

        .card-value {
          .mg-card-img {
            &:hover {
              position: absolute;
              width: 300px;
              height: 300px;
              background: #ddd;
              border: 1px solid #ddd;
              border-radius: 8px;
              z-index: 100;
            }
          }
        }

        &.reveal {
          .mg-card-img {
            &:hover {
              position: relative;
              width: 100%;
              height: 100%;
              background: transparent;
              border: none;
              border-radius: 0px;
            }
          }
          transform: none;
          .front {
            visibility: hidden;
          }
          .back {
            visibility: visible;
          }
        }
        &.matched {
          transform: none;

          .back,
          .front {
            background-color: #46f6c2 !important;
            box-shadow: 0 0 0 2px rgba(#000, 0.05) inset;
            animation: selected 0.8s 0s ease 1;
            // animation-fill-mode: both;
            opacity: 0.2;
          }
          .card-value {
            color: black;
            animation: fading 0.8s 0s ease 1;
            opacity: 0;
          }
        }

        &.closed {
          animation: shake 0.5s;
          animation-iteration-count: 1;
        }

        .front {
          background: #c1b431;
          border-bottom: 6px solid rgba(0, 0, 0, 0.4);
        }
        .back {
          background: #ffed47;
        }
      }

      @keyframes selected {
        0% {
          opacity: 0.4;
        }
        30% {
          opacity: 0.7;
        }
        50% {
          opacity: 1;
        }
        70% {
          opacity: 0.7;
        }
        100% {
          opacity: 0.1;
        }
      }

      @keyframes shake {
        0% {
          transform: translate(1px, 1px) rotate(0deg);
        }
        10% {
          transform: translate(-1px, -2px) rotate(-1deg);
        }
        20% {
          transform: translate(-3px, 0px) rotate(1deg);
        }
        30% {
          transform: translate(3px, 2px) rotate(0deg);
        }
        40% {
          transform: translate(1px, -1px) rotate(1deg);
        }
        50% {
          transform: translate(-1px, 2px) rotate(-1deg);
        }
        60% {
          transform: translate(-3px, 1px) rotate(0deg);
        }
        70% {
          transform: translate(3px, 1px) rotate(-1deg);
        }
        80% {
          transform: translate(-1px, -1px) rotate(1deg);
        }
        90% {
          transform: translate(1px, 2px) rotate(0deg);
        }
        100% {
          transform: translate(1px, -2px) rotate(-1deg);
        }
      }
    }
  }

  .te-flip-image {
    width: 104px !important;
    height: 104px !important;
    background-repeat: no-repeat;
    object-fit: contain;
    background-position-x: 0px;
    background-position-y: 0px;
    margin: 6px;
    transition-duration: 0ms;
  }

  .te-flip-icon {
    width: 73px !important;
    height: 73px !important;
    background-repeat: no-repeat;
    object-fit: contain;
    background-position-x: 0px;
    background-position-y: 0px;
    margin: 0px;
    transition-duration: 0ms;
  }

  .te-flip-timer {
    position: absolute;
    top: 0px;
    right: 0px;
    color: rgba(0, 0, 0, 0.1);
    font-size: 4px;
  }

  .te-grid-12 {
    padding: 40px calc((100% - (157px * 3)) / 2);
  }

  .te-grid-16 {
    padding: 40px calc((100% - (157px * 4)) / 2);
  }

  .te-grid-20 {
    padding: 40px calc((100% - (157px * 5)) / 2);
  }

  .te-grid-24 {
    padding: 40px calc((100% - (157px * 6)) / 2);
  }

  .te-flip-icons {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }

  .card-icon {
    width: 2.25vw;
    height: 2.25vw;
    padding: 10px;
    margin: 15px;
    position: relative;

    img {
      width: 54px;
    }
  }

  .base-timer {
    position: relative;
    width: 300px;
    height: 300px;
  }

  .base-timer__svg {
    transform: scaleX(-1);
  }

  .base-timer__circle {
    fill: none;
    stroke: none;
  }

  .base-timer__path-elapsed {
    stroke-width: 7px;
    stroke: grey;
  }

  .base-timer__path-remaining {
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;
  }

  .base-timer__path-remaining.green {
    color: rgb(65, 184, 131);
  }

  .base-timer__path-remaining.orange {
    color: orange;
  }

  .base-timer__path-remaining.red {
    color: red;
  }

  .base-timer__label {
    position: absolute;
    width: 300px;
    height: 300px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
  }
}

.game-complete-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 6;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 30px;
  a {
    text-decoration: none;
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes wrong-cards-color {
  0% {
    background-color: #ff3f00;
  }
  25% {
    background-color: #ffbb00;
  }
  100% {
    background-color: #ff3f00;
  }
}

@keyframes matching-cards-color {
  0% {
    background-color: #02ccba;
  }
  25% {
    background-color: #02cc16;
  }
  50% {
    background-color: #88ff00;
  }
  75% {
    background-color: #02cc16;
  }
  100% {
    background-color: #02ccba;
  }
}

.fg-timer {
  .timer-wrapper {
    display: flex;
    justify-content: center;
    background-color: black;
    width: 60px;
    margin: auto;
    border-radius: 50%;
  }

  .time-wrapper {
    position: relative;
    width: 10px;
    height: 30px;
    font-size: 30px;
  }

  .time-wrapper .time {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(0);
    opacity: 1;
    transition: all 0.2s;
  }

  .time-wrapper .time.up {
    opacity: 0;
    transform: translateY(-100%);
  }

  .time-wrapper .time.down {
    opacity: 0;
    transform: translateY(100%);
  }
}

.te-textbox-black {
  background-color: black;
  color: white;
  padding: 15px;
  margin: 5px;
  min-width: 10px !important;
  max-width: fit-content;
  border-radius: 15px;
}

.tic-tac-toe {
  .buttons {
    width: fit-content;
    * {
      padding: 20px;
      margin: 20px;
    }
  }
  .black-board {
    background-repeat: no-repeat;
    height: fit-content;
    object-fit: contain;
    background-size: cover;
    padding: 5%;
    max-width: 600px;
    margin: auto;
    .tic-tac-toe-hash {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-evenly;
      background-position: center center;
      position: relative;
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      height: 0;
      padding-top: 82%;
      .cross {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        align-items: center;
        position: absolute;
        z-index: 5;
        top: 50%;
        transform: scale(0.8);
      }
      .cross-line1-h {
        top: 15%;
      }
      .cross-line3-h {
        top: 80%;
      }
      .cross-line1-v {
        right: 28%;
        transform: rotate(90deg) scale(0.8);
      }
      .cross-line2-v {
        transform: rotate(90deg) scale(0.8);
      }
      .cross-line3-v {
        left: 28%;
        transform: rotate(90deg) scale(0.8);
      }
      .cross-line-diag1 {
        transform: rotate(45deg);
      }
      .cross-line-diag2 {
        transform: rotate(135deg);
      }
      .ttt-boxes {
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        align-items: center;
        padding-left: 10%;
        padding-right: 0;
      }
      .tic-tac-toe-box {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 40%;
        flex: 0 0 30%;
        height: 30%;
        border-radius: 10px;
        margin: 1px;
      }
    }

    .ult-tic-tac-toe {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .ult-tic-tac-toe-box {
        flex: 0 0 30%;
        height: 30%;
        border-radius: 10px;
        margin: 1px;
        display: flex;
        flex-wrap: wrap;
        transform: scale(0.9);
        .tic-tac-toe-hash-small {
          width: 100%;
          height: auto;
          position: relative;
          .box-winner {
            width: 100%;
            height: 100%;
            background-size: 45%;
            background-repeat: no-repeat;
            background-position: center center;
            position: relative;
            background-color: rgba(20, 20, 20, 0.41); 

            @include te-bg-filter(5px);
            border-radius: 10px;
            position: absolute;
          }
        }
        .inner-tic-tac-toe-box {
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 40%;
          flex: 0 0 30%;
          height: 30%;
          border-radius: 10px;
          margin: 1px;
        }
      }
    }
  }
}

.spectate-mode {
  width: 600px;
  height: 300px;
  background-color: #0000008f;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  z-index: 2;
  left: 50vw;
  transform: translate(-50%, -60%);
  .exit-spectate {
    position: absolute;
    z-index: 2;
    top: 220px;
    left: 300px;
    transform: translate(-50%);
    pointer-events: auto;
  }
}

.fungames-home {
  margin: 0px 30px 0px 30px;
  @media screen and (max-width:'600px') {
    margin: 0;
    padding: 0;
    .micro-game-area{
      padding: 0 !important;
    }
  }
  .game-cards {
    display: flex;
    .game-card {
      cursor: pointer;
      background-color: #8d8d8d;
      color: black;
      border-radius: 20px;
      height: 300px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      &:hover {
        background-color: white;
      }
    }
  }
}

.te-slider {
  .level-btn{
    margin: 8px 0;
    cursor: pointer;
  }
  .level-btn-unselected{
    background-color: $primaryColor4;
    color: $textColor2;
  }
  .level-btn-selected{
    margin: 0;
    height: 90%;
    color: $primaryColor4;
    background-color: white;
    box-shadow: 0px 1px 5px 0px black;
  }
}

.te-info-box {
  .te-diff-scale {
    height: 6px;
    background: #2a2f52;
    border-radius: 5px;
  }
  .te-diff-level {
    height: 6px;
    background: linear-gradient(124.39deg, #3c50e0 10.16%, #0014a0 93.47%);
    border-radius: 5px;
    transition: width 0.3s linear;
  }
}

.te-lobby-theme {
  @include te-bg-filter(30);
  color: white; 
}

.te-height-240 {
  min-height: 200px;
}

.te-lobby-card-theme {
  background: $primaryColor3;
  @include te-bg-filter(30px);
  color: white;
}

.te-lobby-tabs {
  border-radius: 10px 10px 0px 0px;
}

.te-lobby-card {
  color: white;
  border: solid 1px rgba(255, 255, 255, 0.16);
  background-color: rgba(255, 255, 255, 0.04);
  @media screen and (max-width: 600px){
    background-color: rgba(39, 44, 96, 0.5);
    border-right: 1px solid #4c5180;
  }
  .te-pop-up {
    position: absolute;
    width: 180px;
    background: #f0f4feec;
    color: #0b0b42;
    border-radius: 10px 10px 10px 0px;
    bottom: 60%;
    left: 50%;
    @media only screen and (max-width: 600px) {
      left: 30%;
    }
  }
  .game-card-image{
    width: 100px; 
    height: 100px;
    object-fit: cover;
    @media screen and (max-width: 600px) {
      width: 100%;
      height: 150px;
    }
  }
  .te-pop-up-alt {
    position: absolute;
    width: 220px;
    background: #f0f4feec;
    color: #0b0b42;
    border-radius: 10px 10px 0px 10px;
    bottom: 50%;
    left: 42%;
    @media only screen and (max-width: 600px) {
      left: 20%;
    }
  }
}

.te-lobby-winners-container {
  display: flex;
  justify-content: center;
  transform: translate(-1%, -20px);
}

.te-lobby-winners {
  display: flex;
  position: relative;
  width: 200px;
  height: 200px;
  // margin: 0px 50px 40px 50px;
  .bar3 {
    background: #b6bef5;
    position: absolute;
    width: 43px;
    transition: all 0.5s ease-out;
    clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);
    animation: fillvalue-bar3 0.6s forwards;
    height: 100px;
    top: 120px;
    left: 40px;
    border-radius: 10px 10px 20px 20px;
    transform-origin: 70% 90%;
    opacity: 0;
  }
  .bar3-text {
    position: absolute;
    width: 60px;
    text-align: center;
    transform: rotate(-15deg);
    bottom: 80px;
    left: 0px;
    animation: fadeIn 0.6s 0.6s forwards;
    opacity: 0;
  }
  .bar1 {
    background: #3c50e0;
    position: absolute;
    width: 65px;
    top: 35px;
    left: 72px;
    transition: all 0.5s ease-out;
    clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);
    animation: fillvalue-bar1 0.6s 0.9s forwards ease-out;
    height: 180px;
    border-radius: 10px 10px 20px 20px;
    transform-origin: 50% 100%;
    opacity: 0;
  }
  .bar1-text {
    position: absolute;
    left: 30px;
    bottom: 174px;
    width: 160px;
    text-align: center;
    animation: fadeIn 0.6s 1.5s forwards ease-out;
    opacity: 0;
  }
  .bar2 {
    background: rgb(131, 158, 208);
    position: absolute;
    width: 45px;
    transition: all 0.5s ease-out;
    opacity: 0;
    clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);
    animation: fillvalue-bar2 0.6s 0.3s forwards ease-out;
    height: 140px;
    transform: rotate(15deg);
    top: 80px;
    left: 120px;
    border-radius: 10px 10px 20px 20px;
    transform-origin: 30% 110%;
  }
  .bar2-text {
    position: absolute;
    bottom: 120px;
    width: 100px;
    text-align: center;
    left: 140px;
    transform: rotate(15deg);
    animation: fadeIn 0.6s 0.9s forwards ease-out;
    opacity: 0;
  }
  @keyframes fillvalue-bar2 {
    0% {
      transform: rotate(15deg) scale(1, 0);
      opacity: 100;
    }
    80% {
      transform: rotate(15deg) scale(1, 1.3);
      opacity: 100;
    }
    100% {
      transform: rotate(15deg) scale(1, 1);
      opacity: 100;
    }
  }
  @keyframes fillvalue-bar1 {
    0% {
      transform: scale(1, 0);
      opacity: 100;
    }
    80% {
      transform: scale(1, 1.3);
      opacity: 100;
    }
    100% {
      transform: scale(1, 1);
      opacity: 100;
    }
  }
  @keyframes fillvalue-bar3 {
    0% {
      transform: rotate(-15deg) scale(1, 0);
      opacity: 100;
    }
    80% {
      transform: rotate(-15deg) scale(1, 1.3);
      opacity: 100;
    }
    100% {
      transform: rotate(-15deg) scale(1, 1);
      opacity: 100;
    }
  }
  @keyframes winners-trophy {
    0% {
      transform: rotate(-15deg);
    }
    100% {
      transform: rotate(15deg);
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 100;
    }
  }
}

.fun-game-play-container{
  border: solid 1px rgba(255, 255, 255, 0.16);
  background-color: rgba(255, 255, 255, 0.04);
  min-height: 68vh;
  @media screen and (max-width:991px) {
    min-height: fit-content;
    padding:0;
    .flip-body{
      .playground{
        .flip-card{
          width: 100px;
          height: 100px;
        }
      }
    }
  }
}

.te-game-complete-container{
  position: relative;
  text-align: center;
  background: rgba(22, 36, 71, 0.4);
  min-height: 73vh;
  color: #f4f5f7;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width:600px) {
  min-height: 65vh;
  }
}

.stats-fun-games {
  background-color: #15233f;
  position: relative;
  min-height: 68vh;
  border: solid 1px rgba(255, 255, 255, 0.16);
  background-color: rgba(255, 255, 255, 0.04);
  .fg-leaderboard{
    th{
      color: $primaryColor2;
      font-weight: bolder;
    }
    .rank{
      width: 20%;
    }
    .name{
      width: 50%;
    }
  }
  .stats-button {
    color: white !important;
  }
  .te-fun-games-coins-left-wrapper{
    display: flex;
    justify-content: flex-end;
  }
  .stats-button-timer {
    background-color: white !important;
    color: #0b0b42 !important;
    border: none;
  }
  .timer-coin {
    margin-top: 15px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 15px;

    .te-coin-needed {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 45px;
    }

    // div:nth-of-type(1){
    //   width: 50%;
    // }
    // div:nth-of-type(2){
    //   width: 20%;
    // }
  }
  .player {
    height: 48px;
    width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    .profile-img {
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    div:nth-of-type(2) {
      width: 40%;
    }
    div:nth-of-type(3) {
      width: 10%;
    }
    div:nth-of-type(4) {
      width: 30%;
    }
  }
  .fun-game-progress {
    position: relative;
    bottom: 10%;
    div:nth-of-type(1) {
      width: 25%;
    }
    div:nth-of-type(2) {
      width: 35%;
    }
    div:nth-of-type(3) {
      width: 10%;
    }
  }
  .player-active {
    background-color: #58623b;
    border: solid #c7c416 0.1px;
  }
  .player-inactive {
    background-color: #27344e;
    border: solid #3c485f 0.1px;
  }
}

.te-coins-header {
  @extend .font-sans;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  max-width: 140px;
  // margin: auto;
  color: #e0d932;
  padding: 3px 10px;
  border: 2px solid #e0da329a !important;
  border-radius: 30px !important;
  img {
    width: 36px;
    padding: 0px 6px;
  }
}

.te-rps {
  .rps-table {
    background-image: url(https://cgs-cdn.s3.ap-south-1.amazonaws.com/team-engage/uploads/2021/05/19165157/RPS_Table.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding-bottom: 50px;
    position: relative;
  }
  .rps-play-area {
    background-image: url("https://cgs-cdn.s3.ap-south-1.amazonaws.com/team-engage/uploads/2021/05/20062118/RPS_Frame.png");
    background-repeat: no-repeat;
    background-position: center 120px;
    background-size: 70% 75%;
    width: 100%;
    height: 65vh;
  }
  .rps-choices {
    margin: auto;
    text-align: center;
    button{
      background-size: contain;
      width: 80px;
    }
  }
  .rps-card {
    width: 104px;
    height: 128px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 104px 128px;
    border-radius: 10px;
  }
  .rps-card {
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
  }
  .rps-card:hover {
    transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  .rps-selection {
    padding: 215px 10px 120px 10px;
  }
  .opponent{
    width: 90%;
    max-width: 130px;
    margin: auto;
  }
  .selected-card {
    width: 90%;
    padding-top: 5px;
  }
  .rps-heading {
    text-align: center;
    padding: 30px 0px 0px 0px;
  }
  .demo-div {
    border: 2px dotted;
    aspect-ratio: 0.85;
    width: 90%;
    max-width: 130px;
    margin: auto;
    border-radius: 10px;
  }
  .select-card {
    margin-top: -20px;
  }
  .rps-modal {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #00000094;
  }
  .rps-result {
    margin-top: 150px;
    margin-left: 150px;
    background-color: #606160;
    width: 500px;
    border-radius: 10px;
  }
  .modal-bg {
    background: linear-gradient(
      96.47deg,
      rgba(255, 255, 255, 0.1449) 0.79%,
      rgba(255, 255, 255, 0.0207) 47.81%,
      rgba(255, 255, 255, 0.1127) 99.34%
    );
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.25);
    @include te-bg-filter(15px);
  }
}

.te-lobby-button {
  border-radius: 50%;
  &:hover {
    outline: none;
    box-shadow: 0 0 10px 1px grey;
  }
}

.te-bottom-border {
  border-bottom: 1px solid #ffffff1a;
}

.te-round-large {
  border: 0px 8px 8px 8px;
}

.te-lobby-list {
  list-style-type: none;
  display: inline-flex;
  margin-right: 4px;
}

.te-lobby-list-item {
  display: inline;
  padding: 5px 0px 8px 0px;
  border: #1f4068 3px solid;
  border-radius: 50%;
  margin-left: -16px;
  font-weight: 600;
  font-size: 16px;
  width: 40px;
  height: 40px;
  img {
    object-fit: cover;
  }
}

.share-input {
  width: 1px;
  height: 1px;
  opacity: 0;
}

.card-hov {
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
}
.card-hov:hover {
  transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.te-pop-up-fungame {
  position: absolute;
  z-index: 4;
  //color: black;
  width: 220px;
  background: #f0f4fe;
  color: #0b0b42;

  @include te-bg-filter(15px);
  border-radius: 10px 10px 10px 0px;
  bottom: 15%;
  left: 35%;
  @media only screen and (max-width: 600px) {
    left: 30%;
  }
}

.show-quit {
  position: absolute;
  background: white;
  z-index: 4;
  left: 50%;
  transform: translateX(-50%);
  top: 30%;
  width: 400px;
  height: 100px;
  background: rgba(0, 0, 0, 0.6);

  @include te-bg-filter(15px);
}

.stats-pop-up {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translate(-16px, -8px);
  z-index: 4;
  background: rgba(29, 29, 29, 0.2);

  @include te-bg-filter(2px);
  .pop-up {
    background: rgba(17, 17, 17, 0.99);
    border-radius: 16px;
    width: fit-content;
    max-width: 95%;
    padding: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    .close-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
    .pop-up-buttons {
      display: flex;
      justify-content: space-evenly;
      margin: 10px 0px;
      padding: 0px;
      a {
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
}

.te-proceed-sticky {
  position: sticky;
  width: 100%;
  padding: 0 6vw;
  bottom: 0px;
  left: 0px;
  background: #edf1ff;
  .direction-button{
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 600;
  }
}

.admin-img-container {
  background-color: red;
  width: 50%;
  padding-top: 50%;
  position: relative;
  margin: auto;
  background: #b4bcf5;
  color: #3c50e0;
  img {
    height: 100%;
    object-fit: cover;
  }
  .admin-name {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.coins-left {
  color: #e0d932;
  background: #15233f;
  border-radius: 40px 5px 30px 40px;
  border: solid #e0d93263 1px;
  display: flex;
  .coins-icon {
    background-image: url("../images/coins-left.png");
    width: 35px;
    background-repeat: no-repeat;
  }
}
.micro-game-area {
  position: relative;
  width: 100%;
  overflow: hidden;
  .micro-game-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 6;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    table {
      min-width: 300px;
      min-height: none;
    }
  }
  .menu-container {
  }
}
