$primaryColor1: #5E17E1;
$primaryColor2: #d6449c;
$primaryColor3: #5067F6;
$primaryColor4: #0E0F2F;
$primaryColor5: #ffffff;

$gradientColor1 : #363179;
$gradientColor2 : #1E1A4C;

$primaryBgColor: #4a2a86;

$textColor1: #798bfc;
$textColor2: #9cc2ff;

$bgGradient1 : #252C6D;
$bgGradient2 : #181D45;
